.trigger {
    position: relative;
    overflow: hidden;
}

.triggerPreview {
    position: absolute;
    z-index: 1;
}

.logo {
    align-items: center;
}

.colorPicker {
    z-index: 1031;
}

.colorPreviewField {
    position: relative;
}

.colorPreview {
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 28px;
    top: 7px;
    left: 10px;
    border-radius: 15px;

    &_white {
        border: solid 2px #979797;
    }
}

.colorPreviewInput {
    padding-left: 48px;
}

.colorPopover {
    :global(.popover) {
        box-shadow: 0 0 10px rgba(0,0,0,0.20);
    }

    :global(.popover-body) {
        padding: 19px 24px;
    }
}

.colorBox {
    width: 168px !important;
    margin-right: -9px !important;
    margin-bottom: -9px !important;

    & > span > div {
        margin-bottom: 9px !important;
        margin-right: 9px !important;
        width: 19px !important;
        height: 19px !important;
    }

    div[title="#ffffff"] {
        border: solid 1px #979797;
    }
}

.switcher {
    line-height: 16px;
    padding-left: 8px;
    padding-right: 0;
    top: -6px;

    &:global(.custom-switch .custom-control-label) {
        display: flex;
        min-height: 32px;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding-right: 48px;

        &::before {
            background-color: #ccc;
            border-color: #ccc !important;
            border-radius: 12px;
            width: 40px;
            height: 24px;
            right: 0;
            top: 6px;
            left: auto;
            box-shadow: none !important;
        }

        &::after {
            left: auto;
            right: 20px;
            width: 16px;
            height: 16px;
            top: 10px;
            border-radius: 8px;
            background-color: #fff;
        }
    }

    &:global(.custom-switch .custom-control-input:checked ~ .custom-control-label) {
        &::before {
            background-color: #26C164;
            border-color: #26C164 !important;
        }

        &::after {
            transform: translateX(16px);
        }
    }
}
