@import "../../../styles/config";

.drop {
    height: 16px;
    padding-top: 5px;
    transition: height 0.3s ease;
    margin: 0 -40px; // make the droppable area wider to be easier to hit

    &_active {
        height: 54px;
    }
}

.dropLine {
    height: 0;
    border: solid 3px transparent;
    border-radius: 2px;

    &_dragging {
        border-color: #ccc;
        border-style: dashed;
    }

    &_active {
        border-color: $primary-color;
    }
}

.dragHandle {
    position: relative;
    display: block;
    height: 18px;
    width: 18px;
    visibility: hidden;

    &_active {
        cursor: move;
        visibility: visible;
    }

    i {
        font-size: 18px;
        line-height: 1;
        color: #B4BBC6;
    }
}

.dragContent {
    position: relative;
    flex: 1 1 auto;
}

.remove:global(.btn.btn-flat) {
    position: absolute;
    top: 3px;
    right: 2px;
    color: #B4BBC6;
    border: none;
    background: transparent;
}

.color {
    position: absolute;
    top: 11px;
    right: 42px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 0 1px inset rgba(0, 0, 0, 0.5);
}

.statusName {
    background-color: #fafafa;

    &_deleted {
        text-decoration: line-through;
    }
}

.dragged {
    opacity: 0;
    // height: 0;
}