
.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #7F8FA4;
    color: white;
    text-align: center;
    line-height: 50px;
    font-size: 18px;

    &_xl {
        width: 80px;
        height: 80px;
        font-size: 34px;
        line-height: 80px;
    }

    &_img {
        background: none;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}