@import "../../../styles/config";

.noteZero{
  &_image {
  	max-height: 100%;
  }
}

.browserIcon {
	height: 35px;
	padding: 0 10px;
}
