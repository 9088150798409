
.trigger {
    -webkit-font-smoothing: antialiased;
    /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    zoom: 1;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;      
    font-size: 14px;
    position: fixed;
    cursor: pointer;
    z-index: 2147483645;
    border-radius: 8px 8px 0 0;
    padding: 8px 16px 13px;
    will-change: transform;
    user-select: none;
    transition: transform 0.3s ease;

    &:hover {
        box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.24);
    }

    &_middleLeft {
        transform: translateX(-5px) rotate(90deg) translate(-50%, -100%);
        transform-origin: 0 0;
        top: 50%;
        left: 0;

        &:hover {
            transform: rotate(90deg) translate(-50%, -100%);
        }
    }

    &_middleRight {
        transform: translateX(5px) rotate(-90deg) translate(50%, -100%);
        transform-origin: 100% 0;
        top: 50%;
        right: 0;

        &:hover {
            transform: rotate(-90deg) translate(50%, -100%);
        }
    }
}