.showResolved {
    color: #7F8FA4;
    font-size: 13px;
    font-weight: 600;
    padding: 8px 0 0;

    & label {
        padding: 0px 13px 0 7px;
    }

    & label:before {
        border-color: #7F8FA4 !important;
        border-width: 2px;
    }
}