.thumbs {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 10px;
    padding: 16px 10px;
    border-left: 1px solid #E6EAEE;
    overflow: hidden auto;

    &>* {
        flex-shrink: 0;
    }
}

.image,
.pending {
    width: 90px;
    height: 50px;
    border-radius: 4px;
}

.image {
    object-fit: cover;
    cursor: pointer;

    &:not(&_selected):hover {
        box-shadow: rgba(0, 66, 255, 0.3) 0px 0px 3px 2px;
    }

    &_selected {
        box-shadow: rgba(0, 66, 255, 0.8) 0px 0px 3px 2px;
    }
}

.add {
    align-self: center;
    width: 40px;
    height: 40px;
    padding: 0;

    &:global(.btn)>i:global(.material-icons) {
        font-size: 20px;
    }
}

@media (max-width: 992px) {
    .thumbs {
        margin-top: 55px; // compensate the resolve bar in tablet
    }
}