.commentForm {
    position: absolute;
    z-index: 2;
    transform: translate(45px, 33px);
    transition: opacity 0.2s ease;
    background: white;
    width: 340px;
    border-radius: 10px;
    border: 1px solid #dfe4f1;
    // margin: -13px 0 0 -2px;
    box-shadow: 0 10px 28px 0 rgba(0,0,0,0.16);
    &:before {
      top: 0;
      right: 0;
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top: 8px solid #fefefe;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      margin: 32px 0 0 -16px;
      z-index: 1;
      margin-right: 37px;
      margin-top: -16px;
    }

    &_hidden {
      opacity: 0;
    }

    &.commentForm_mirror:before {
      // display: none;
      margin-right: 300px;
    }

    &.commentForm_dropUp:before {
      top: auto;
      bottom: -16px;
      transform: none;
    }

    &__body {
      padding: 16px;
      padding-top: 21px;
      padding-bottom: 0;

      &_hasAuthorName {
        padding-top: 16px;
      }
    }
    &__footer {
      padding: 14px 19px 17px 21px;
      display: flex;
      align-items: center;
    }
      &__footer .btn-link {
        color: #7F8FA4;
        position: relative;
        flex-grow: 0;
        top: 2px; }

        &__footer :global(.btn.btn-primary) {
          font-size: 12px;
          font-weight: bold;
        }
      
        &__dropZone {
          flex: 1;
          border-radius: 8px;
          margin-left: -6px;

          &_active {
            background: rgba(0, 66, 255, 0.05);
          }
        }

        &__attachButton:global(.btn.btn-flat) {
          float: right; 
          padding: 5px 10px;
          color: #979a9c;

          :global(i.material-icons) {
            font-size: 24px !important;
          }

          &:hover, &:active {
            background: none !important;
            border-color: transparent !important;
            color: #393c3e !important;
          }
        }

        &__saveButton {
          border-radius: 10px;
        }

        &__hotkeys {
          width: 0;
          overflow: visible;
          color: #7F8FA4;
          white-space: nowrap;
          font-size: 12px;
          padding-bottom: 4px;
        }
      }

.attachments {
  max-height: 150px;
  overflow-y: auto;
}

.attachmentPreview {
  margin-top: 4px;
  margin-right: 2px;

  &:first-child {
    margin-top: 10px;
  }
}

.close {
  top: 14px;
  position: absolute;
  right: 21px;
  z-index: 123;
}

.closeIcon {
  font-size: 17px;
}

.authorField {
  padding-bottom: 0;
}
.authorInput {
  color: #495057;
  font-size: 16px;

  &, &:focus, &:active {
    border: none;
  }
}

.draggableNumber {
  position: absolute;
  left: 0;
  top: 0;
}