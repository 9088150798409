@import "../../../styles/config";

.noteNav {
	position: static;
	display: none;
	padding: 10px 0;
	z-index: 4;

	@include breakpoint(md) {
		display: block;
	}

	//visible only on mobile
	.menu-button {
		position: relative;

		a {
			padding: .275rem .55rem;
		}

		i {
			position: relative;
			top: 2px;
			// font-size: 16px;
		}
	}
}

//this is both the menu button and the close button
.showNotesToggle {
	display: none;

	@include breakpoint(md) {
		display: inline-block;
	}
}

.commentsContainer {
	&__resolvedHeader {
		border-bottom: solid 1px $border-color;

		@include breakpoint(md) {
			display: none;
		}

		:global(.btn) {
			white-space: nowrap;
		}
	}
}