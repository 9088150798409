@import "../styles/config";


.inner {
	line-height: 1;
	display: inline-block;
	width: 100%;
	height: 1em;
	// border:1px solid $border-color;
    // margin: auto;
    // margin-bottom:$baseline;
	// width: $product-card-width;
	// height: 311px;
	background-image:
		linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
		linear-gradient( tint($border-color,30%) 100%, transparent 0 );
	
	background-repeat: no-repeat;

	background-size: 50% 100%, 100% 100%;

	background-position: 0 0, 0 0;

	animation: shine 1s infinite;

	@include breakpoint(sm) {
		width: 100%;
	}

	&_inline {
		width: auto;
		color: transparent;
	}
}


@keyframes shine {
	to {
		background-position: 100% 0, 0 0;
	}
}