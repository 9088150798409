/*
WARNING: this file has to be in sync with `widget.css`. The only difference is the color: #0042FF <-> #3E3F3A
Whenever one of the files is updated, so should be the other
*/

@charset "UTF-8";
@import url("~bootstrap/dist/css/bootstrap.min.css");
@import "../fonts/source-sans-pro/source-sans-pro.css";
@import "../fonts/material-icons/material-icons.css";
@import "../fonts/material-icons-outlined/material-icons-outlined.css";
.container {
  padding: 0 40px; }

@media (max-width: 768px) {
  .text-left-sm {
    text-align: left !important; }
  .text-center-sm {
    text-align: center !important; } }

section.inverse-section h1, section.inverse-section h2, section.inverse-section h3, section.inverse-section h4, section.inverse-section h5, section.inverse-section h6, section.inverse-section p, section.inverse-section small, section.inverse-section li {
  color: white !important; }

* {
  margin: 0;
  padding: 0; }

html {
  max-width: 100%;
  overflow-x: hidden; }

body {
  font: 100%;
  font-size: 16px;
  color: #354052;
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
  width: 100%;
  line-height: 1.5rem;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  zoom: 1;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  background-color: #fff;
  padding-top: 65px; }
  @media (max-width: 992px) {
    body {
      padding-top: /*48px*/ 57px; } }

.flex {
  display: flex; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

* html .clearfix {
  zoom: 1; }

/* IE6 */
*:first-child + html .clearfix {
  zoom: 1; }

/* IE7 */
.flex {
  display: flex; }

.eq {
  flex: 1; }

img {
  height: auto; }

.base-line-height {
  line-height: 16px; }

.small-line-height {
  line-height: 18px; }

.full-width {
  width: 100%; }

.max-width {
  max-width: 100%; }

.display-block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.jumbotron {
  background: #F5F8FA; }

.primary-bg {
  background-color: #0042FF !important; }

.cursor-pointer {
  cursor: pointer; }

.helper-icon {
  font-size: 18px;
  position: relative;
  top: 4px;
  margin-left: 5px; }

.border-top, .border-bottom, .border-left, .border-right {
  border-color: #E6EAEE; }

.attachment-grid {
  padding-left: 50px; }

.attachment-preview {
  position: relative;
  margin: 4px;
  padding: 8px;
  width: 120px;
  height: 75px;
  background: #354052;
  display: block;
  border-radius: 8px;
  border: 1px solid #E6EAEE;
  color: white; }
  .attachment-preview:hover {
    background: #3f4a5b;
    text-decoration: none; }
    .attachment-preview:hover .title {
      color: white; }
    .attachment-preview:hover .remove {
      display: block; }
  .attachment-preview .remove {
    position: absolute;
    top: -10px;
    right: -10px;
    display: none; }
  .attachment-preview .type {
    position: absolute;
    bottom: 0px;
    left: 8px; }

.text-uppercase {
  text-transform: uppercase; }

.extra-light-text-color {
  color: #B4BBC6 !important; }

.light-text-color {
  color: #7F8FA4 !important; }

.text-color {
  color: #354052 !important; }

.success-text-color {
  color: #26C164 !important; }

.dark-text-color {
  color: #393C3E !important; }

.primary-text-color {
  color: #0042FF !important; }

.text-success {
  color: #26C164; }

.text-warning {
  color: #FFAA00; }

.text-danger {
  color: #FF4E4A; }

.link-color {
  color: #0042FF; }

.regular-font {
  font-weight: 400; }

.medium-font {
  font-weight: 600; }

.bold-font {
  font-weight: 700; }

.extra-bold-font {
  font-weight: 900; }

.light-font-name {
  font-weight: 300; }

.primary-text-color {
  color: #0042FF !important; }

.secondary-text-color {
  color: #0B2A5C !important; }

.micro-text {
  font-size: 0.7rem; }

h1 {
  font-size: 30px;
  line-height: 38px; }

h2 {
  font-size: 24px;
  line-height: 31px; }

h3 {
  font-size: 17px;
  line-height: 22px; }

h4 {
  font-size: 14px;
  line-height: 18px; }

h5 {
  font-size: 13px;
  line-height: 15px; }

h6 {
  font-size: 10px;
  line-height: 12px; }

ul.check-list {
  list-style: none;
  padding: 0; }
  ul.check-list li {
    font-size: 16px;
    padding-left: 1.3em;
    margin-bottom: 8px; }
    ul.check-list li:before {
      content: "check";
      /* FontAwesome Unicode */
      font-family: Material Icons;
      margin-left: -1.3em;
      /* same as padding-left set on li */
      width: 1.3em;
      /* same as padding-left set on li */
      position: relative;
      top: 4px;
      font-size: 15px;
      color: font-color;
      margin-right: 10px; }

ul.icon-list li {
  font-size: 14px;
  line-height: 20px; }
  ul.icon-list li i {
    font-size: 14px;
    position: relative;
    top: 3px;
    margin-right: 5px; }

ul.icon-list-better li {
  line-height: 1.3rem;
  position: relative; }
  ul.icon-list-better li:before {
    font-family: 'material icons';
    content: 'check';
    color: #0042FF; }
  ul.icon-list-better li.no-deal {
    color: #7F8FA4; }
    ul.icon-list-better li.no-deal:before {
      color: #7F8FA4;
      content: 'clear'; }

.line-through {
  text-decoration: line-through; }

.tooltip-highlight {
  border-bottom: 1px dashed #a1a4a7;
  cursor: pointer;
  display: inline; }

@media (max-width: 768px) {
  .text-center-sm {
    text-align: center !important; } }

a {
  color: #0042FF; }

.btn {
  border-radius: 8px;
  font-weight: 600;
  font-size: 13px;
  transition: none; }
  .btn .material-icons, .btn .material-icons-outlined {
    vertical-align: middle;
    line-height: 0 !important;
    position: relative;
    top: -1px;
    font-size: 14px; }
  .btn.btn-lg {
    padding: .65rem 2rem;
    font-size: 16px; }
  .btn.btn-sm-text {
    font-size: 14px; }
    .btn.btn-sm-text.dropdown-toggle:after {
      vertical-align: 0.10em; }
  .btn.btn-circle {
    border-radius: 100px; }
  .btn.btn-primary {
    background: #0042FF;
    border-color: #0042FF; }
    .btn.btn-primary:hover {
      background: #0035cc;
      border-color: #0035cc; }
    .btn.btn-primary:active {
      background: #002180 !important;
      border-color: #002180 !important; }
  .btn.btn-secondary {
    background: white;
    border-color: #B4BBC6;
    color: #354052; }
    .btn.btn-secondary:hover {
      background: #f2f6ff;
      border-color: #CAD2E8;
      color: #002180; }
    .btn.btn-secondary:active {
      background: #edf2ff !important;
      color: #002180 !important;
      border-color: #3368ff !important; }
    .btn.btn-secondary.disabled {
      border-color: #B4BBC6; }
  .btn.btn-terciary {
    background: #f0f4ff;
    border-color: #f0f4ff;
    border-radius: 100px;
    padding: 5px 25px;
    color: #0042FF; }
    .btn.btn-terciary:hover {
      background: #d1ddff;
      border-color: #d1ddff; }
  .btn.btn-label {
    background: #F5F8FA;
    border: none; }
    .btn.btn-label.disabled {
      opacity: 1 /*100%*/; }
  .btn.btn-danger {
    background: #FF4E4A;
    border-color: #FF4E4A; }
    .btn.btn-danger:hover {
      background: #cc3e3b;
      border-color: #cc3e3b; }
  .btn.btn-success {
    background: #26C164;
    border-color: #26C164; }
    .btn.btn-success:hover {
      background: #1e9a50;
      border-color: #1e9a50; }
  .btn.btn-warning {
    background: #FFAA00;
    border-color: #FFAA00;
    color: white; }
    .btn.btn-warning:hover {
      background: #cc8800;
      border-color: #cc8800; }
  .btn.btn-flat {
    background: transparent;
    border-color: white;
    color: #354052; }
    .btn.btn-flat:hover {
      background: #f2f6ff;
      border-color: #CAD2E8;
      color: #002180;
      border-color: white; }
    .btn.btn-flat:active {
      background: #edf2ff !important;
      color: #002180 !important;
      border-color: #3368ff !important; }
  .btn.btn-wire {
    background: transparent;
    border-color: white;
    color: white; }
    .btn.btn-wire:hover {
      background: #0035cc;
      border-color: #0035cc; }
  .btn.btn-link {
    color: #0042FF; }
    .btn.btn-link:hover {
      color: #003be6; }
  .btn.btn-icon i {
    font-size: 20px !important; }
  .btn:focus {
    outline: none;
    box-shadow: none !important; }

.close {
  color: #0042FF;
  opacity: .8; }

.tooltip {
  margin-bottom: 4px;
  font-size: 12px; }

.margin-auto {
  margin: 0 auto; }

.margin {
  margin: 16px !important; }

.margin-top {
  margin-top: 16px !important; }

.margin-right {
  margin-right: 16px !important; }

.margin-bottom {
  margin-bottom: 16px !important; }

.margin-left {
  margin-left: 16px !important; }

.double-margin-top {
  margin-top: 32px !important; }

.double-margin-right {
  margin-right: 32px !important; }

.double-margin-bottom {
  margin-bottom: 32px !important; }

.double-margin-left {
  margin-left: 32px !important; }

.triple-margin-top {
  margin-top: 48px !important; }

.triple-margin-right {
  margin-right: 48px !important; }

.triple-margin-bottom {
  margin-bottom: 48px !important; }

.triple-margin-left {
  margin-left: 48px !important; }

.half-margin-top {
  margin-top: 8px !important; }

.half-margin-right {
  margin-right: 8px !important; }

.half-margin-bottom {
  margin-bottom: 8px !important; }

.half-margin-left {
  margin-left: 8px !important; }

.quarter-margin-top {
  margin-top: 4px !important; }

.quarter-margin-right {
  margin-right: 4px !important; }

.quarter-margin-bottom {
  margin-bottom: 4px !important; }

.quarter-margin-left {
  margin-left: 4px !important; }

.no-margin {
  margin: 0px !important; }

.no-margin-top {
  margin-top: 0px !important; }

.no-margin-right {
  margin-right: 0px !important; }

.no-margin-bottom {
  margin-bottom: 0px !important; }

.no-margin-left {
  margin-left: 0px !important; }

.large-margin-top {
  margin-top: 48px !important; }

.padded {
  padding: 16px !important; }

.padding-top {
  padding-top: 16px !important; }

.padding-right {
  padding-right: 16px !important; }

.padding-bottom {
  padding-bottom: 16px !important; }

.padding-left {
  padding-left: 16px !important; }

.double-padded {
  padding: 32px !important; }

.double-padding-top {
  padding-top: 32px !important; }

.double-padding-right {
  padding-right: 32px !important; }

.double-padding-bottom {
  padding-bottom: 32px !important; }

.double-padding-left {
  padding-left: 32px !important; }

.triple-padding-top {
  padding-top: 48px !important; }

.triple-padding-right {
  padding-right: 48px !important; }

.triple-padding-bottom {
  padding-bottom: 48px !important; }

.triple-padding-left {
  padding-left: 48px !important; }

.no-padding {
  padding: 0px !important; }

.no-padding-top {
  padding-top: 0px !important; }

.no-padding-right {
  padding-right: 0px !important; }

.no-padding-bottom {
  padding-bottom: 0px !important; }

.no-padding-left {
  padding-left: 0px !important; }

.half-padding {
  padding: 8px !important; }

.half-padding-top {
  padding-top: 8px !important; }

.half-padding-right {
  padding-right: 8px !important; }

.half-padding-bottom {
  padding-bottom: 8px !important; }

.half-padding-left {
  padding-left: 8px !important; }

.quarter-padding {
  padding: 4px !important; }

.quarter-padding-top {
  padding-top: 4px !important; }

.quarter-padding-right {
  padding-right: 4px !important; }

.quarter-padding-bottom {
  padding-bottom: 4px !important; }

.quarter-padding-left {
  padding-left: 4px !important; }

.no-border-top {
  border-top: none !important; }

.no-border-bottom {
  border-bottom: none !important; }

@media (max-width: 768px) {
  .no-padding-sm {
    padding: 0 !important; } }

section {
  padding: 32px 0; }
  section.inverse-section {
    background-color: #0042FF; }
  section.off-white-bg {
    background-color: #F5F8FA; }

.tab-nav {
  text-align: center; }
  .tab-nav li {
    margin: 15px;
    text-transform: uppercase;
    font-size: 13px; }
    .tab-nav li:first-of-type {
      margin-left: 0; }
    .tab-nav li:last-of-type {
      margin-right: 0; }
    .tab-nav li a {
      color: #7F8FA4;
      padding: 0;
      padding-bottom: 5px;
      font-weight: 600; }
      .tab-nav li a.active {
        color: #354052;
        border-bottom: 3px solid #0042FF; }

.navbar a.nav-link {
  color: #354052 !important;
  transition-duration: 0.13s; }
  .navbar a.nav-link:hover {
    color: #050608 !important; }

.navbar.bg-light {
  background-color: white !important; }

.page-header {
  padding-top: 16px; }

.page-header-for-title {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E6EAEE; }
  .page-header-for-title .title {
    margin-bottom: 0; }

.primary-tabs a.nav-link {
  font-size: 16px;
  padding: 4px 0px;
  margin: 0 12px;
  border: none;
  color: #7F8FA4; }
  .primary-tabs a.nav-link:first-of-type {
    margin-left: 0; }
  .primary-tabs a.nav-link:hover {
    color: #0042FF; }
  .primary-tabs a.nav-link.active {
    border-bottom: 2px solid #0042FF;
    color: #0042FF; }

.mention-menu {
  min-width: 280px; }

.list-of-options .list-group-inner {
  position: relative; }

.list-of-options .list-group.disabled .list-group-item {
  opacity: .7;
  cursor: not-allowed !important; }

.list-of-options .list-group.disabled input[type="checkbox"]:checked + .list-group-item:before, .list-of-options .list-group.disabled input[type="radio"]:checked + .list-group-item:before {
  color: #B4BBC6; }

.list-of-options .list-group-item {
  user-select: none;
  border-width: 1px;
  margin-bottom: 10px;
  transition-duration: .1s;
  border-color: #E6EAEE;
  width: 100%;
  border-radius: 8px; }
  .list-of-options .list-group-item:hover {
    background-color: #f2f6ff; }
  .list-of-options .list-group-item.select-all {
    border: none; }
    .list-of-options .list-group-item.select-all:hover {
      background: transparent; }

.list-of-options .list-group input[type="checkbox"]:checked + .select-all {
  background: none !important; }

.list-of-options .list-group {
  width: 100%; }

.list-of-options .list-group input[type="checkbox"] {
  display: none; }
  .list-of-options .list-group input[type="checkbox"] + .list-group-item {
    cursor: pointer; }
    .list-of-options .list-group input[type="checkbox"] + .list-group-item:before {
      position: absolute;
      top: calc(50% - 14px);
      left: 15px;
      z-index: 99;
      font-size: 30px;
      color: #B4BBC6;
      font-family: "Material Icons";
      content: "radio_button_unchecked"; }
  .list-of-options .list-group input[type="checkbox"]:checked + .list-group-item {
    background-color: #f2f6ff;
    border-color: #0042FF; }
    .list-of-options .list-group input[type="checkbox"]:checked + .list-group-item:before {
      color: #0042FF;
      content: "check_circle"; }

.list-of-options .list-group input[type="radio"] {
  display: none; }
  .list-of-options .list-group input[type="radio"] + .list-group-item {
    cursor: pointer; }
    .list-of-options .list-group input[type="radio"] + .list-group-item:before {
      position: absolute;
      top: calc(50% - 14px);
      left: 15px;
      z-index: 99;
      font-size: 30px;
      color: #B4BBC6;
      font-family: "Material Icons";
      content: "radio_button_unchecked"; }
  .list-of-options .list-group input[type="radio"]:checked + .list-group-item {
    background-color: #f2f6ff;
    border-color: #0042FF; }
    .list-of-options .list-group input[type="radio"]:checked + .list-group-item:before {
      color: #0042FF;
      content: "check_circle"; }

.list-of-options.style-checkbox .list-group input[type="checkbox"] + .list-group-item:before {
  content: "check_box_outline_blank";
  transition: all 0.2s linear 0s; }

.list-of-options.style-checkbox .list-group input[type="checkbox"]:checked + .list-group-item:before {
  content: "check_box"; }

.list-of-options.style-checkbox .list-group input[type="radio"] + .list-group-item:before {
  content: "check_box_outline_blank";
  transition: all 0.2s linear 0s; }

.list-of-options.style-checkbox .list-group input[type="radio"]:checked + .list-group-item:before {
  content: "check_box"; }

.list-of-options.simple .list-group-item {
  margin-bottom: 0px;
  border: none;
  padding: 10px 0 10px 0; }
  .list-of-options.simple .list-group-item:hover {
    background-color: transparent; }

.list-of-options.simple .list-group input[type="checkbox"] + .list-group-item:before {
  left: -5px; }

.list-of-options.simple .list-group input[type="checkbox"]:checked + .list-group-item {
  background-color: transparent; }

.list-of-options.simple .list-group input[type="radio"] + .list-group-item:before {
  left: -5px; }

.list-of-options.simple .list-group input[type="radio"]:checked + .list-group-item {
  background-color: transparent; }

.nav-tabs .nav-item .nav-link.active {
  background: transparent; }

.panel {
  border: 1px solid #0042FF;
  border-radius: 8px;
  background: #f2f6ff; }
  .panel .panel-body {
    padding: 20px; }
    @media (max-width: 992px) {
      .panel .panel-body {
        padding: 16px; } }
  .panel.panel-gray {
    border: none;
    background: #F5F8FA; }

.lifetime-redeem-panel {
  min-height: 305px; }
  @media (max-width: 768px) {
    .lifetime-redeem-panel {
      min-height: auto; } }

.code-panel {
  border-radius: 8px;
  background: #fafbfc;
  border: 1px solid #E6EAEE; }
  .code-panel .panel-body {
    padding: 20px; }
    @media (max-width: 992px) {
      .code-panel .panel-body {
        padding: 16px; } }
    .code-panel .panel-body code {
      color: #7F8FA4; }

.modal-content {
  border-radius: 16px; }
  .modal-content .modal-header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px; }
    .modal-content .modal-header .close {
      padding: 1rem 1.4rem; }
  .modal-content .modal-footer {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px; }

.simple-prepend .input-group-prepend, .simple-prepend .form-control, .simple-prepend .input-group-text {
  border: none;
  background-color: transparent; }

.simple-prepend .input-group-text {
  padding-left: 0;
  padding-right: 0;
  color: #B4BBC6; }

.form-control {
  border-radius: 8px; }
  .form-control.form-control-lg {
    font-size: 16px;
    padding-top: .65rem;
    padding-bottom: .65rem; }
  .form-control:focus {
    box-shadow: none;
    border-color: #0042FF; }

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7F8FA4;
  opacity: 1;
  /* Firefox */ }

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7F8FA4; }

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7F8FA4; }

.chip {
  display: inline-block;
  padding: 0 16px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #e6ecff;
  color: #0042FF; }
  .chip .close {
    position: relative;
    left: 8px; }
    .chip .close i {
      line-height: 32px;
      font-size: 24px;
      color: #0042FF; }
  .chip.promo-code-chip {
    border: 1px solid #0042FF;
    background: white;
    color: #354052;
    height: 44px;
    line-height: 40px;
    border-radius: 100px; }
    .chip.promo-code-chip .close {
      line-height: 40px; }

.input-group .input-group-append .input-group-text {
  background-color: white; }

.custom-radio [type="radio"]:checked,
.custom-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.custom-radio [type="radio"]:checked + label,
.custom-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block; }

.custom-radio [type="radio"]:checked + label:before,
.custom-radio [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #354052;
  border-radius: 100%;
  background: #fff; }

.custom-radio [type="radio"]:checked + label:after,
.custom-radio [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #0042FF !important;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.custom-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.custom-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.custom-checkbox [type="checkbox"]:checked,
.custom-checkbox [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.custom-checkbox [type="checkbox"]:checked + label,
.custom-checkbox [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block; }

.custom-checkbox [type="checkbox"]:checked + label:before,
.custom-checkbox [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #354052;
  border-radius: 4px;
  background: #fff; }

.custom-checkbox [type="checkbox"]:checked + label:after,
.custom-checkbox [type="checkbox"]:not(:checked) + label:after {
  width: 16px;
  height: 16px;
  background: #0042FF;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 1px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  line-height: 16px;
  font-family: 'Material Icons';
  content: 'check';
  color: white;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.custom-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.custom-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #26C164; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

/* duplicated password-requirement, see below */

.alert-promo {
  background-image: linear-gradient(270deg, #0013FF 0%, #CC00FF 100%);
  color: white;
  border-radius: 0;
  margin: 0;
  z-index: 99;
  font-size: 17px;
  padding: 0px;
  position: fixed;
  top: 0;
  width: calc(100%-250px);
  left: 250px;
  right: 0; }
  @media (max-width: 992px) {
    .alert-promo {
      display: none; } }
  .alert-promo .btn {
    margin: 10px 0;
    margin-left: 10px;
    background: #FFAA00;
    color: #000;
    border: none; }
    .alert-promo .btn:hover, .alert-promo .btn:active {
      background: #ffbb33 !important;
      color: #000 !important;
      border: none !important; }
  .alert-promo .close {
    box-shadow: none; }
    .alert-promo .close:hover {
      color: white; }

.alert {
  font-size: 14px; }
  .alert .alert-link {
    font-weight: 600;
    text-decoration: underline; }
  .alert.alert-info {
    background-color: #e6ecff; }

/*
.toast-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  min-height: 200px; }
  .toast-container .toast-container-inner {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 1031; }
*/

.toast-container {
  position: fixed;
  left: 15px;
  bottom: 15px;
  z-index: 1051; }

.toast-header {
  background-color: transparent;
  border: none; }
  .toast-header .close {
    color: white;
    text-shadow: none; }

.toast {
  min-width: 200px;
  max-width: 280px;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.8) !important; }
  .toast p {
    margin: 0; }

.card-checkbox-fix {
  border: 1px solid #E6EAEE;
  border-radius: 16px;
  display: block; }

.card {
  border: 1px solid #E6EAEE;
  border-radius: 16px;
  display: block; }
  .card .card-header {
    background: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: none;
    overflow: hidden; }
  .card .card-list {
    font-size: 15px; }
    .card .card-list i {
      font-size: 18px;
      position: relative;
      top: 4px;
      color: #7F8FA4; }
  .card .card-footer {
    border: none;
    background-color: transparent;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden; }

.product-card-container {
  max-width: 950px; }
  @media (max-width: 992px) {
    .product-card-container {
      max-width: 720px; } }

.product-card-wrapper {
  position: relative;
  max-width: 448px;
  margin: 0 auto;
  display: block;
  margin-bottom: 32px; }
  @media (max-width: 768px) {
    .product-card-wrapper {
      width: 100%;
      max-width: 448px; } }
  .product-card-wrapper:hover .card-url {
    color: #354052 !important; }
  .product-card-wrapper:hover .product-card {
    border-color: #0042FF;
    box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.15), 0 0 0 1px #0042FF; }

.card-actions {
  position: absolute;
  bottom: 20px;
  right: 20px; }
  .card-actions .menu-button {
    padding: 10px !important;
    border: none; }
    .card-actions .menu-button:hover {
      background-color: transparent;
      border: none; }
  .card-actions .dropdown-item:hover {
    cursor: pointer; }

a.product-card {
  box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 20px rgba(87, 112, 237, 0.13);
  position: relative;
  border: none;
  display: block;
  transition-timing-function: ease-in-out;
  transition: 0.1s;
  border: 1px solid #E6EAEE; }
  a.product-card .card-header {
    border: none;
    position: relative;
    padding: 0px;
    height: 250px; }
    @media (max-width: 768px) {
      a.product-card .card-header {
        height: auto; } }
    a.product-card .card-header .thumbnail-wrapper {
      background-color: #E6EAEE;
      background-size: 100%;
      border: none;
      border-bottom: none;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      width: 100%;
      height: 100%;
      position: relative; }
      @media (max-width: 768px) {
        a.product-card .card-header .thumbnail-wrapper {
          min-height: 230px; } }
      a.product-card .card-header .thumbnail-wrapper .thumbnail {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        height: 70%;
        width: 80%;
        background-size: 100% auto;
        background-position: center top;
        background-repeat: no-repeat;
        background-color: transparent; }
      a.product-card .card-header .thumbnail-wrapper .no-img {
        transition-timing-function: ease-in-out;
        transition: 0.1s;
        width: 90px;
        height: 90px;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.5);
        text-align: center;
        border-radius: 100px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        z-index: 99; }
        a.product-card .card-header .thumbnail-wrapper .no-img p {
          margin: 0;
          position: relative;
          font-size: 37px;
          top: 31px;
          color: white; }
      .product-card .card-header .thumbnail-wrapper .overlay {
        transition-timing-function: ease-in-out;
        transition: 0.2s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0; }
        .product-card .card-header .thumbnail-wrapper .overlay .actions {
          position: relative;
          text-align: center;
          margin: 0 auto;
          top: calc(50% - 19px); }
          .product-card .card-header .thumbnail-wrapper .overlay .actions .btn {
            padding-left: 40px;
            padding-right: 40px; }
  a.product-card .card-body {
    z-index: 99;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px; }
    a.product-card .card-body .card-url {
      color: #354052;
      font-size: 18px;
      margin-bottom: 4px !important; }
    a.product-card .card-body .card-text {
      color: #B4BBC6; }
  a.product-card:hover {
    text-decoration: none; }
    a.product-card:hover .card-body .card-url {
      color: #0042FF; }
    a.product-card:hover .no-img {
      border-color: white !important; }
    a.product-card:hover .overlay {
      opacity: 1 !important; }

.free-trial-card {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 270px;
  border: 2px solid #0042FF;
  z-index: 99; }
  .free-trial-card p {
    margin-bottom: 0px; }
  .free-trial-card a.close {
    position: absolute;
    right: 10px;
    top: 10px; }
  .free-trial-card .btn {
    max-width: 130px; }

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #FF8E4A;
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 18px; }
  .avatar.avatar-xs {
    width: 30px;
    height: 30px;
    font-size: 13px;
    line-height: 30px; }
  .avatar.avatar-micro {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    font-weight: bold; }
  .avatar.avatar-xl {
    width: 80px;
    height: 80px;
    font-size: 34px;
    line-height: 80px; }
  .avatar.avatar-rounded {
    border-radius: 8px; }

.avatars-list .avatar {
  margin-left: -8px;
  border: 2px solid white;
  cursor: pointer; }
  .avatars-list .avatar:first-of-type {
    margin-left: 0; }
  .avatars-list .avatar.avatar-xs {
    width: 34px;
    height: 34px; }

.btn-group.show .btn.dropdown-action {
  background: #edf2ff !important;
  color: #002180 !important;
  border-color: #3368ff !important; }

.dropdown.show .btn-secondary:not(.btn-no-dropdown), .dropdown.show .btn-flat:not(.btn-no-dropdown) {
  background: #edf2ff !important;
  color: #002180 !important;
  border-color: #3368ff !important; }

.dropdown-visible {
  display: block; }

.dropdown-menu {
  box-shadow: 0 8px 48px -4px rgba(16, 19, 54, 0.24);
  border: none;
  border-radius: 8px; }
  .dropdown-menu .dropdown-item {
    color: #354052;
    transition-duration: .2s;
    font-size: 13px;
    font-weight: 600; }
    .dropdown-menu .dropdown-item.dropdown-item-hover,
    .dropdown-menu .dropdown-item:hover {
      background: #F5F8FA; }
    .dropdown-menu .dropdown-item:active {
      background: #e9ecee;
      color: #354052; }
    .dropdown-menu .dropdown-item.active {
      background: #F5F8FA;
      box-shadow: inset 3px 0 0 #0042FF;
      color: #393C3E; }
      .dropdown-menu .dropdown-item.active:hover {
        background: #F5F8FA; }
    .dropdown-menu .dropdown-item i {
      vertical-align: middle;
      line-height: 0 !important;
      position: relative;
      top: -2px;
      font-size: 20px;
      left: -5px; }
    .dropdown-menu .dropdown-item .icon-left {
      margin-right: 12px; }

.filter-dropdown .dropdown-header {
  color: #354052;
  background-color: #FAFBFC;
  border-top: 1px solid #E6EAEE;
  border-bottom: 1px solid #E6EAEE; }
  .filter-dropdown .dropdown-header.dropdown-header-white {
    border: none;
    background: transparent; }

.max-height-dropdown {
  overflow-y: auto; /* changed for better UI on windows */
  background: white;
  max-height: 300px; }

.dropdown-subtle .btn-secondary {
  border: none; }

.checkbox-filter-item {
  cursor: pointer;
  position: relative; }
  .checkbox-filter-item:hover {
    background: transparent !important; }
    .checkbox-filter-item:hover:after {
      content: "check_box";
      color: #0042FF;
      font-family: "material Icons Outlined"; }
  .checkbox-filter-item:after {
    content: "check_box_outline_blank";
    position: absolute;
    top: 3px;
    right: 18px;
    font-size: 20px;
    color: #B4BBC6;
    font-family: "material Icons"; }
  .checkbox-filter-item.active {
    background: transparent !important;
    box-shadow: none !important; }
    .checkbox-filter-item.active:after {
      content: "check_box";
      color: #0042FF;
      font-family: "material Icons"; }

.workspace-menu-inner {
  position: relative;
  overflow-y: /*scroll*/ auto;
  height: 100%;
  width: 100%;
  background: white;
  max-height: 300px;
  padding-bottom: /*50px*/ 66px; }

.add-team-option {
  position: absolute;
  bottom: 0px;
  left: 0;
  background: white;
  width: 100%;
  /*border-radius: 8px;*/
  margin-bottom: 8px; }
  .add-team-option .dropdown-divider {
    margin-top: 0; }

.workspace-menu {
  min-width: 280px;
  position: absolute !important;
  animation-duration: .15s;
  animation-fill-mode: both;
  animation-name: menu-zoom; }

.admin-menu {
  position: absolute !important;
  animation-duration: .15s;
  animation-fill-mode: both;
  animation-name: menu-zoom;
  top: 83%;
  bottom: 44px !important; }

.menu-avatar .avatar-container {
  margin-right: 12px; }
  .menu-avatar .avatar-container .avatar-default {
    padding: 6px;
    background-color: #0042FF;
    font-size: 14px;
    font-weight: 600;
    color: white;
    border-radius: 6px;
    line-height: 1;
    min-width: 28px;
    text-align: center; }
  .menu-avatar .avatar-container.invert-avatar .avatar-default {
    background: white;
    color: #354052;
    border: 1px solid #E6EAEE; }

.menu-with-button .dropdown-item {
  padding: 12px 20px; }
  .menu-with-button .dropdown-item .text {
    padding: 0 !important; }
  .menu-with-button .dropdown-item .button-container {
    padding: 0 !important;
    line-height: 1; }
    .menu-with-button .dropdown-item .button-container button {
      padding: 0 !important; }
    .menu-with-button .dropdown-item .button-container i {
      font-size: 20px; }

.product-card-loading:empty {
  border: 1px solid #E6EAEE;
  margin: auto;
  margin-bottom: 16px;
  width: 448px;
  height: 311px;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(#eef0f3 190px, transparent 0), linear-gradient(#eef0f3 20px, transparent 0), linear-gradient(#eef0f3 20px, transparent 0);
  background-repeat: no-repeat;
  background-size: 50px 400px, calc(100% - 40px) 200px, 300px 200px, 250px 200px;
  background-position: 0 0, 20px 20px, 20px 230px, 20px 260px;
  animation: shine 1s infinite; }
  @media (max-width: 768px) {
    .product-card-loading:empty {
      width: 100%;
      max-width: 448px; } }

@keyframes shine {
  to {
    background-position: 100% 0, 20px 20px, 20px 230px, 20px 260px; } }

.badge {
  border-radius: 100px;
  font-size: 11px; }
  .badge.badge-danger {
    background-color: #FF4E4A; }

.list-default .row {
  padding: 16px 0;
  border-bottom: 1px solid #E6EAEE; }

#mobile-nav {
  z-index: 800;
  display: none;
  width: 100%;
  background: white;
  border-bottom: 1px solid #E6EAEE; }
  @media (max-width: 992px) {
    #mobile-nav {
      display: block; } }

#dashboard-container, #mobile-nav {
  padding-left: 250px; }
  @media (max-width: 992px) {
    #dashboard-container, #mobile-nav {
      padding-left: 0; } }

.close-x {
  padding: 8px;
  border-radius: 100px;
  border: 2px solid #b8bbbe;
  margin: 0;
  color: #354052;
  line-height: 1; }
  .close-x .material-icons {
    font-size: 14px;
    line-height: 1;
    position: relative;
    top: 1px; }

.menu-close {
  position: absolute;
  top: 15px;
  right: 15px; }

#main-nav {
  transition-duration: .2s;
  position: fixed;
  width: 250px;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: #F9FCFF;
  z-index: 900;
  border-right: 1px solid #E6EAEE;
  padding: 0 20px;
  padding-top: 40px; }
  @media (max-width: 992px) {
    #main-nav {
      left: -250px; } }
  #main-nav.menu-open {
    left: 0 !important; }
    @media (max-width: 992px) {
      #main-nav.menu-open {
        left: 0 !important; } }
  #main-nav .menu-text {
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 1.2; }
  #main-nav .admin-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    border-top: 1px solid #E6EAEE; }
    #main-nav .admin-bar .dropup:hover > .dropdown-menu {
      display: block; }
  #main-nav .logo-container {
    margin-bottom: 32px; }
  #main-nav .navbar-brand {
    padding: 0; }
  #main-nav .navbar {
    align-items: start; }
    #main-nav .navbar .nav-link {
      padding: 8px 0;
      margin: 0;
      display: block;
      width: 100%; }
      #main-nav .navbar .nav-link.active {
        font-weight: 900; }
  #main-nav .nav-item.workspace-selector .workspace-menu .menu-title {
    margin: 0;
    font-size: 14px; }
  #main-nav .menu-toggle {
    border-radius: 0; }
    @media (max-width: 992px) {
      #main-nav .menu-toggle {
        padding: 16px 20; } }
    @media (max-width: 576px) {
      #main-nav .menu-toggle {
        padding: 16px 20px; } }
    #main-nav .menu-toggle .material-icons {
      line-height: 30px; }
  #main-nav .dropdown-toggle:after {
    display: none; }
  #main-nav .dropdown-toggle .material-icons {
    font-size: 16px;
    position: relative;
    top: 3px;
    color: #B4BBC6; }

html.market-html {
  scroll-behavior: smooth; }

.spacer {
  height: 96px;
  margin-bottom: -96px;
  position: relative;
  top: -96px; }

.video-button-wrapper {
  position: relative;
  width: 100%; }

.video-btn {
  padding: 15px 20px 15px 20px;
  position: relative;
  top: 250px;
  margin: 0 auto;
  color: #0042FF;
  background: white !important; }
  .video-btn:hover, .video-btn:active {
    color: #0042FF; }
  .video-btn i {
    line-height: 13px;
    position: relative;
    top: 6px;
    margin-right: 8px; }

.video-modal .modal-dialog {
  max-width: 800px;
  margin: 30px auto; }

.video-modal .modal-body {
  position: relative;
  padding: 0px; }

.video-modal .close {
  position: absolute;
  right: -30px;
  top: 0;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1; }

.browser-wrapper {
  padding-top: 30px; }
  .browser-wrapper .browser-header {
    border: 1px solid #E6EAEE;
    height: 28px;
    width: 100%;
    border-bottom: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .browser-wrapper img {
    border: 1px solid #E6EAEE; }

body.market {
  padding-top: 87px !important; }

.market section {
  padding: 64px 0; }
  @media (max-width: 768px) {
    .market section {
      padding: 16px 0; } }
  .market section#main-hero {
    position: relative;
    padding-bottom: 288px;
    padding-top: 16px; }
    @media (max-width: 768px) {
      .market section#main-hero {
        padding-top: 0; } }

#primary-header {
  width: 100%;
  padding: 20px 0; }
  #primary-header .btn-link {
    padding-left: 0px;
    padding-right: 15px; }
    @media (max-width: 992px) {
      #primary-header .btn-link {
        color: #0042FF; } }
  #primary-header .btn-secondary {
    padding-left: 12px;
    padding-right: 12px; }

#marketing-header {
  position: fixed;
  width: 100%;
  background: white !important;
  padding: 20px 0;
  z-index: 1000;
  top: 0; }
  #marketing-header .nav .nav-item {
    font-size: 18px; }

.text-container {
  position: relative; }
  .text-container .accent-text {
    position: absolute;
    top: 0;
    left: -30px;
    font-weight: 900;
    font-size: 76px;
    opacity: 0.08;
    color: #0042FF; }

p.market {
  font-size: 18px;
  line-height: 27px; }

h1.market {
  font-size: 61px;
  line-height: 59px; }
  @media (max-width: 1200px) {
    h1.market {
      font-size: 52px;
      line-height: 50px; } }
  @media (max-width: 992px) {
    h1.market {
      font-size: 40px;
      line-height: 44px; } }

h2.market {
  font-size: 41px;
  line-height: 50px; }

h3.market {
  font-size: 23px;
  line-height: 32px; }

.volley-jumbotron {
  background: #0042FF;
  color: white;
  border-radius: 10px;
  background: #0042FF url("../images/marketing/pattern-jumbotron.svg") center; }

.note-placement-wrapper {
  position: relative; }

.market-note {
  position: absolute;
  z-index: 999; }
  .market-note.note-1 {
    right: 40%;
    bottom: -260px; }
    @media (max-width: 768px) {
      .market-note.note-1 {
        right: 20%; } }
  .market-note .note-detail-body {
    position: relative;
    width: 338px;
    height: 210px; }
    @media (max-width: 768px) {
      .market-note .note-detail-body {
        width: 270px; } }
    @media (max-width: 768px) {
      .market-note .note-detail-body .comment-form {
        width: 270px; } }
    .market-note .note-detail-body .comment-form .comment-form-body .text-wrapper {
      padding: 10px;
      border: 1px solid #0042FF;
      border-radius: 2px; }
    .market-note .note-detail-body .comment-number {
      position: absolute;
      right: 25px;
      top: -65px;
      animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both; }
    .market-note .note-detail-body .comment-form {
      position: absolute;
      animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both; }

.comment-text {
  position: relative;
  right: 100px; }
  @media (max-width: 768px) {
    .comment-text {
      right: 15px; } }

.typer p {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #7F8FA4;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  width: 153px;
  animation: typing 3s steps(25, end), blink-caret 1s step-end infinite; }

#mc_embed_signup .button {
  background: white !important;
  color: #0042FF !important;
  font-weight: 700;
  font-size: 18px !important;
  height: 46px !important;
  letter-spacing: 0 !important; }

#mc_embed_signup input.email {
  font-size: 18px !important;
  height: 46px !important;
  padding-left: 16px !important;
  border: 1px solid white !important;
  margin-right: 10px !important;
  background: rgba(255, 255, 255, 0.9) !important; }

/* The typing effect */
@keyframes typing {
  0% {
    width: 0;
    opacity: 0; }
  46% {
    opacity: 0;
    width: 0; }
  47% {
    opacity: 1; }
  100% {
    width: 153px;
    opacity: 1; } }

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to {
    border-color: transparent; }
  50% {
    border-color: #7F8FA4; } }

/* ----------------------------------------------
 * Generated by Animista on 2019-7-11 0:8:32
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

/* ----------------------------------------------
 * Generated by Animista on 2019-7-11 0:11:54
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.projects-video-wrapper {
  max-width: 510px;
  margin: 0 auto;
  margin-bottom: 24px;
  border-radius: 10px;
  overflow: hidden; }

.welcome-panel {
  background: #F5F8FA url("../images/welcome-bg.svg") center center no-repeat; }

.add-new-product-bar input {
  border: 3px solid #cfd3d6;
  height: 59px;
  box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.06); }

.add-new-product-bar button {
  box-shadow: 0 8px 19px 0 #BAC9F2;
  padding: 15.5px 2rem !important;
  font-size: 16px; }

#bottom-bar {
  border-top: 1px solid #bcc2ce; }
  #bottom-bar .product-title {
    position: relative;
    top: 3px; }
    @media (max-width: 768px) {
      #bottom-bar .product-title .product-name {
        /* width: 120px; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 13px; } }
  @media (max-width: 768px) {
    #bottom-bar .btn {
      padding-left: 7px;
      padding-right: 7px; } }
  #bottom-bar a.mode-link {
    padding: 16px 15px 7px 15px;
    margin: 0 5px;
    border-radius: 4px; }
    #bottom-bar a.mode-link svg {
      height: 24px; }
      #bottom-bar a.mode-link svg path {
        transition: fill 0.13s ease; }
      #bottom-bar a.mode-link svg .icon {
        fill: #B4BBC6; }
    #bottom-bar a.mode-link:hover {
      text-decoration: none; }
      #bottom-bar a.mode-link:hover svg .icon {
        fill: #7e838b; }
    #bottom-bar a.mode-link.comment svg {
      position: relative;
      top: 3px; }
    #bottom-bar a.mode-link.active {
      background: #0042FF; }
      #bottom-bar a.mode-link.active svg .icon {
        fill: white; }
    @media (max-width: 768px) {
      #bottom-bar a.mode-link {
        padding: 10px 10px 7px 10px;
        margin: 0 2px; }
        #bottom-bar a.mode-link svg {
          height: 18px; } }

.web-view-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }
  .web-view-container.comment-mode {
    cursor: crosshair; }
    .web-view-container.comment-mode .iframe-wrapper .iframe-border .overlay-container .overlay-wrapper {
      background: rgba(0, 0, 0, 0.1); }
    .web-view-container.comment-mode .iframe-wrapper .iframe-border .overlay-container .overlay-border {
      border-color: #0042FF; }
    .web-view-container.comment-mode .iframe-wrapper .iframe-border .overlay-container .overlay-helper {
      position: fixed;
      bottom: 60px;
      text-align: center;
      width: 100%;
      display: flex; }
      .web-view-container.comment-mode .iframe-wrapper .iframe-border .overlay-container .overlay-helper .overlay-helper-inner {
        padding: 4px 20px;
        border-radius: 4px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        background: #0042FF;
        display: flex;
        margin: 0 auto; }
        .web-view-container.comment-mode .iframe-wrapper .iframe-border .overlay-container .overlay-helper .overlay-helper-inner p {
          margin-bottom: 0;
          color: white;
          font-size: 14px; }
  .web-view-container.adding-notes {
    pointer-events: none;
    filter: blur(8px);
    -webkit-filter: blur(8px); }
    .web-view-container.adding-notes .overlay-border {
      border-color: transparent !important; }
  .web-view-container .iframe-wrapper {
    height: 100%;
    width: 100%;
    padding-bottom: 61px;
    background: #9B9B9B;
    position: relative; }
    .web-view-container .iframe-wrapper .iframe-border {
      width: 100%;
      height: 100%;
      border: none;
      margin-left: auto;
      margin-right: auto;
      background: #fff;
      position: relative; }
      .web-view-container .iframe-wrapper .iframe-border .overlay-container {
        position: absolute;
        height: 100%;
        width: 100%;
        pointer-events: none;
        top: 0;
        left: 0; }
        .web-view-container .iframe-wrapper .iframe-border .overlay-container .overlay-wrapper {
          background: transparent;
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          pointer-events: none; }
        .web-view-container .iframe-wrapper .iframe-border .overlay-container .overlay-border {
          position: absolute;
          height: 100%;
          width: 100%;
          pointer-events: none;
          top: 0;
          left: 0;
          border: solid 6px transparent; }
      .web-view-container .iframe-wrapper .iframe-border iframe {
        width: 100%;
        height: 100%;
        border: none; }

.review-container {
  padding-bottom: 61px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex; }
  .review-container.unavailable {
    padding-bottom: 0; }
    .review-container.unavailable .note-container {
      width: 100%; }
    .review-container.unavailable .note-info {
      padding-top: 0 !important; }

.notes-list-container {
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  width: 300px;
  display: flex;
  overflow: hidden; }
  @media (max-width: 992px) {
    .notes-list-container {
      position: absolute;
      height: 100%;
      width: 300px;
      left: 0px;
      top: 0;
      bottom: 0;
      z-index: 5;
      display: none; }
      .notes-list-container.notes-bar-open {
        display: block; } }
  .notes-list-container .notes-list-body {
    position: relative;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-top: 58px;
    padding-bottom: 44px;
    border-right: 1px solid #E6EAEE;
    background: white; }
    .notes-list-container .notes-list-body.promo-active {
      padding-bottom: 183px; }
    @media (max-width: 768px) {
      .notes-list-container .notes-list-body {
        padding-bottom: 85px; } }
    .notes-list-container .notes-list-body .notes-list-header {
      width: 300px;
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      background: white;
      border-right: 1px solid #E6EAEE; }
      /*.notes-list-container .notes-list-body*/ .notes-list-header .filter-button {
        padding: 5px 10px; }
    .notes-list-container .notes-list-body .note-thumbnail {
      padding: 16px;
      cursor: pointer;
      background: white;
      position: relative;
      transition-duration: .2s;
      padding: 16px; }
      .notes-list-container .notes-list-body .note-thumbnail:hover {
        background: #F5F8FA; }
      .notes-list-container .notes-list-body .note-thumbnail img {
        border: 3px solid #E6EAEE;
        border-radius: 4px; }
      .notes-list-container .notes-list-body .note-thumbnail.active {
        background: #0042FF;
        box-shadow: 0 3px 25px rgba(87, 112, 237, 0.5); }
        .notes-list-container .notes-list-body .note-thumbnail.active h5, .notes-list-container .notes-list-body .note-thumbnail.active h6 {
          color: white !important; }
      .notes-list-container .notes-list-body .note-thumbnail h5 {
        margin-bottom: 8px; }
    /*.notes-list-container .notes-list-body*/ .notes-list-footer {
      position: fixed;
      bottom: 62px;
      left: 0;
      background: white;
      width: 100%;
      width: 300px;
      border-right: 1px solid #E6EAEE;
      padding: 0px 0px 10px 20px; }
    /*.notes-list-container .notes-list-body*/ .notes-footer-upgrade-promo {
      bottom: 96px;
      padding: 16px;
      background-color: rgba(255, 255, 255, 0.9); }
      /*.notes-list-container .notes-list-body*/ .notes-footer-upgrade-promo .alert-container .alert {
        border-radius: 8px; }

.check-btn button {
  color: #7F8FA4; }
  .check-btn button i {
    font-size: 14px;
    position: relative;
    right: 6px;
    top: 3px; }

.comment-list-confirm-overlay {
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 319px;
  height: 100%;
  z-index: 3; }
  @media (max-width: 768px) {
    .comment-list-confirm-overlay {
      width: 100%;
      top: 278px; } }
  .comment-list-confirm-overlay .confirm-items {
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%; }
    @media (max-width: 768px) {
      .comment-list-confirm-overlay .confirm-items {
        top: 40px; } }

.note-container {
  width: calc(100% - 300px);
  height: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden; }
  @media (max-width: 992px) {
    .note-container {
      width: 100%; } }
  .note-container .note-body {
    overflow-y: scroll;
    position: relative;
    height: 100%;
    width: 100%; }
    .note-container .note-body .note-nav {
      /* width: calc(100% - $note-container-width); */
      width: calc(100% - 300px);
      position: fixed;
      top: 0;
      padding: 10px 0;
      z-index: 4;
      background-color: white;
      display: none; }
      @media (max-width: 992px) {
        .note-container .note-body .note-nav {
          width: 100%;
          display: block; } }
    .note-container .note-body .note-info {
      display: flex;
      height: 100%;
      width: 100%; }
      @media (max-width: 768px) {
        .note-container .note-body .note-info {
          padding-top: 54px; } }
      .note-container .note-body .note-info .img-container {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        width: calc(100% - 320px);
        background: #FAFBFC;
        position: relative; }
        @media (max-width: 768px) {
          .note-container .note-body .note-info .img-container {
            width: 100%; } }
        .note-container .note-body .note-info .img-container .img-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          position: relative; }
          .note-container .note-body .note-info .img-container .img-wrapper .img-comment-container {
            position: relative;
            margin-left: auto;
            margin-right: auto;
            padding: 10px; }
            @media (max-width: 768px) {
              .note-container .note-body .note-info .img-container .img-wrapper .img-comment-container {
                position: absolute;
                top: 0; } }
            .note-container .note-body .note-info .img-container .img-wrapper .img-comment-container img {
              display: block;
              max-width: 100%;
              height: auto;
              border: 1px solid #E6EAEE; }
      .note-container .note-body .note-info .comments-container {
        height: 100%;
        overflow-y: scroll;
        display: flex;
        width: 320px;
        background: white;
        border-left: 1px solid #E6EAEE;
        position: relative; }
        @media (max-width: 768px) {
          .note-container .note-body .note-info .comments-container {
            position: fixed;
            width: 100%;
            right: 0px;
            top: 278px;
            z-index: 1031;
            box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.2);
            display: none; }
            .note-container .note-body .note-info .comments-container.comments-bar-open {
              visibility: visible;
              display: block; } }
        .note-container .note-body .note-info .comments-container .resolved-header {
          position: fixed;
          width: 320px;
          background-color: white;
          top: 0;
          z-index: 99;
          border-bottom: #E6EAEE 1px solid; }
          @media (max-width: 992px) {
            .note-container .note-body .note-info .comments-container .resolved-header {
              display: none; } }
          .note-container .note-body .note-info .comments-container .resolved-header .page-btn {
            position: relative; }
        .note-container .note-body .note-info .comments-container .comments-wrapper {
          width: 100%;
          position: relative;
          padding-top: 60px; }
          @media (max-width: 768px) {
            .note-container .note-body .note-info .comments-container .comments-wrapper {
              padding-top: 40px;
              padding-bottom: 120px; } }
          .note-container .note-body .note-info .comments-container .comments-wrapper .mobile-comments-header {
            position: fixed;
            top: 272px;
            width: 100%;
            background: white !important;
            z-index: 1040;
            border-radius: 10px; }
          .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment {
            width: 100%;
            padding: 16px;
            position: relative; }
            @media (max-width: 768px) {
              .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment {
                position: fixed;
                background: white;
                z-index: 1032;
                bottom: 0;
                border-top: 1px solid #E6EAEE;
                padding: 7px;
                padding-top: 10px; } }
            .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment textarea {
              resize: none; }
            .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment .add-comment-btn {
              display: none; }
            .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment .attachment {
              display: none; }
            .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment.focused {
              margin-bottom: 16px; }
              @media (max-width: 768px) {
                .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment.focused {
                  margin-bottom: 0; } }
              .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment.focused textarea {
                height: 65px; }
              .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment.focused .add-comment-btn {
                display: block; }
              .note-container .note-body .note-info .comments-container .comments-wrapper .new-comment.focused .attachment {
                display: block; }
          .note-container .note-body .note-info .comments-container .comments-wrapper .comments-list-container {
            width: 100%;
            position: relative; }
            .note-container .note-body .note-info .comments-container .comments-wrapper .comments-list-container .comment {
              padding: 16px;
              width: 100%;
              position: relative;
              padding-bottom: 10px; }
              .note-container .note-body .note-info .comments-container .comments-wrapper .comments-list-container .comment img {
                border-radius: 4px;
                margin-bottom: 5px; }
              .note-container .note-body .note-info .comments-container .comments-wrapper .comments-list-container .comment .actions {
                position: absolute;
                right: 10px;
                top: 6px; }

.note-comment-container {
  display: block;
  width: 100%;
  padding: /*16px 0;*/ 10px 0 16px;
  border-bottom: 1px solid #E6EAEE; }

.note-details-container {
  display: block;
  width: 100%;
  padding-top: 0; }
  .note-details-container .note-details {
    padding: 0 10px 0 22px; }
    .note-details-container .note-details i {
      transform: rotate(0deg);
      margin-right: 10px; }
    .note-details-container .note-details a.collapsed i {
      transform: rotate(-90deg); }
    .note-details-container .note-details a.details-toggle {
      display: block;
      color: #354052; }
      .note-details-container .note-details a.details-toggle i {
        font-size: 18px; }
      .note-details-container .note-details a.details-toggle:hover {
        text-decoration: none; }
    .note-details-container .note-details .note-details-items {
      padding: 10px;
      padding-left: 30px; }
      .note-details-container .note-details .note-details-items dl {
        font-size: 12px;
        margin-bottom: 8px;
        line-height: 1.2; }
        .note-details-container .note-details .note-details-items dl dt {
          color: #B4BBC6; }
        .note-details-container .note-details .note-details-items dl dd {
          margin-bottom: 0;
          word-wrap: break-word; }

.show-notes-toggle {
  display: none;
  position: relative; }
  @media (max-width: 992px) {
    .show-notes-toggle {
      display: inline-block; } }

.note-detail-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 1; }
  .note-detail-container a.close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
    color: white;
    opacity: 1; }
    .note-detail-container a.close i {
      color: white; }
  .note-detail-container .note-detail-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -50px; }
    .note-detail-container .note-detail-wrapper .note-detail-body {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      padding: 10px; }
      .note-detail-container .note-detail-wrapper .note-detail-body .note-detail-helper {
        color: white;
        background: rgba(0, 0, 0, 0.65);
        padding: 10px;
        display: table;
        margin: 0 auto;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 10px; }
      .note-detail-container .note-detail-wrapper .note-detail-body img {
        display: block;
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.04); }
  .note-detail-container .note-actionbar {
    position: fixed;
    width: 100%;
    height: 70px;
    background: white;
    border-top: 1px solid #E6EAEE;
    bottom: 0px;
    left: 0;
    padding: 16px; }

.comment-form {
  background: white;
  width: 340px;
  border-radius: 4px;
  border: 1px solid #D0D0D0;
  margin: -13px 0 0 -2px;
  box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.04); }
  .comment-form:before {
    top: 0;
    right: 0;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top: 8px solid #fefefe;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin: 32px 0 0 -16px;
    z-index: 1;
    margin-right: 37px;
    margin-top: -16px; }
  .comment-form .comment-form-header {
    padding: 16px; }
  .comment-form .comment-form-body {
    padding: 16px;
    padding-top: 0;
    padding-bottom: 0; }
  .comment-form .comment-form-footer {
    padding: 16px; }
    .comment-form .comment-form-footer .btn-link {
      color: #7F8FA4;
      position: relative;
      top: 2px; }

.attachment-thumbnail {
  display: block;
  border: 1px solid #E6EAEE;
  margin-bottom: 8px;
  padding: 4px;
  margin-top: 10px;
  text-decoration: none !important;
  transition-duration: .2s;
  border-radius: 8px;
  position: relative; }
  .attachment-thumbnail img {
    width: 30px;
    box-shadow: none !important;
    border: 1px solid #E6EAEE;
    margin-right: 8px; }
  .attachment-thumbnail .attachment-title {
    margin: 0;
    color: #354052;
    line-height: 26px; }
  .attachment-thumbnail .actions {
    position: absolute;
    right: 0px;
    top: 0px; }
    .attachment-thumbnail .actions i {
      font-size: 18px;
      color: #7F8FA4; }

.comment-number {
  background: #26C164;
  text-align: center;
  width: 39px;
  height: 39px;
  border-radius: 100px;
  color: white;
  line-height: 39px;
  font-weight: 500;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24); }
  .comment-number.comment-number-sm {
    width: 27px;
    height: 27px;
    line-height: 27px;
    font-size: 14px; }

.notes-zero {
  display: flex;
  height: 100%; }
  .notes-zero .zero-notes-container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.note-zero {
  background: #FAFBFC;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .note-zero {
      justify-content: initial; } }
  .note-zero img {
    max-width: 100%;
    z-index: 0; }
  .note-zero .note-zero-text {
    margin: 0 auto; }
    .note-zero .note-zero-text ul, .note-zero .note-zero-text ol {
      margin-top: 16px; }
      .note-zero .note-zero-text ul li, .note-zero .note-zero-text ol li {
        line-height: 30px; }
  .note-zero .btn {
    font-size: 14px; }

.new-items i {
  font-size: 10px; }

.get-started-deck {
  max-width: 875px; }
  .get-started-deck .card-footer {
    padding-top: 0; }

.people-list .media {
  border-bottom: 1px solid #E6EAEE; }
  .people-list .media:last-of-type {
    border-bottom: none; }

.upgrade-button-container .btn {
  border-radius: 100px;
  border: 1px solid #80a1ff; }
  .upgrade-button-container .btn:hover {
    border: 1px solid #0042FF; }

@keyframes menu-zoom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-24px) scale3d(0.9, 0.9, 0.9);
    transform: translateY(-24px) scale3d(0.9, 0.9, 0.9); }
  50% {
    opacity: 1; } }

.welcome-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%; /* modified */
  display: flex; /* added */
  background: #0042FF url("../images/marketing/pattern.svg"); }
  .welcome-container .form-container {
    padding: 0 64px;
    /*position: absolute;
    top: 0;
    left: 0;*/
    width: 50%;
    max-width: 600px;
    /*height: 100%;*/
    background: white; }
    @media (max-width: 768px) {
      .welcome-container .form-container {
        width: 100%;
        max-width: 100%;
        padding: 0 16px; } }
    .welcome-container .form-container .form-header {
      position: relative; }
      .welcome-container .form-container .form-header .close {
        position: absolute;
        right: 0;
        top: 16px;
        z-index: 99; }
      .welcome-container .form-container .form-header .back {
        position: absolute;
        left: -32px;
        top: 16px;
        z-index: 99; }
    .welcome-container .form-container .form-content-wrapper {
      position: relative;
      display: flex;
      height: 100%;
      width: 100%; }
    .welcome-container .form-container .form-content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%; }

.password-requirement {
  font-size: 10pt; }
  .password-requirement:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 8px;
    background: #003be6; }
  .password-requirement.completed {
    color: #B4BBC6; }
    .password-requirement.completed:before {
      background: #B4BBC6; }

.invoice-list .row .paid i {
  color: #26C164; }

.invoice-list .row .unpaid i {
  color: #FF4E4A; }

.invoice-list .row i {
  font-size: 12px; }

.border-text {
  width: 100%;
  display: block; }
  .border-text:before {
    display: block;
    height: 1px;
    background: #E6EAEE;
    width: 100%;
    position: relative;
    top: 24px;
    content: "";
    z-index: -1; }
  .border-text h1, .border-text h2, .border-text h3, .border-text h4, .border-text h5, .border-text h6, .border-text p {
    background: white;
    padding: 4px 20px;
    display: inline-block;
    z-index: 999; }

.strikethrough {
  display: inline; }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 13px;
    right: 2px;
    border-top: 8px solid;
    border-color: #FFAA00;
    z-index: 99;
    top: 13px;
    transform: rotate(-20deg); }

.card.price-card {
  min-height: 536px;
  max-width: 350px;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
  border: 1px solid #cfd3d6; }
  @media (max-width: 992px) {
    .card.price-card {
      min-height: auto; } }
  .card.price-card.popular {
    position: relative; }
    .card.price-card.popular:before {
      content: "POPULAR";
      position: absolute;
      top: 20px;
      right: -124px;
      width: 300px;
      height: 20px;
      line-height: 20px;
      transform: rotate(45deg);
      font-size: 12px;
      font-weight: 900;
      color: white;
      background: #FFAA00;
      text-align: center;
      display: block;
      z-index: 99; }
  .card.price-card .card-header {
    position: relative;
    border-bottom: 1px solid #E6EAEE;
    border-top: 1px solid #E6EAEE;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px; }
    .card.price-card .card-header .card-promo {
      color: #002eb3; }
  .card.price-card .card-body {
    min-height: 287px; }
    @media (max-width: 992px) {
      .card.price-card .card-body {
        min-height: 0px; } }
  .card.price-card .card-header, .card.price-card .card-body, .card.price-card .card-footer {
    padding: 20px; }
  .card.price-card .card-header {
    padding-top: 16px; }
    .card.price-card .card-header .btn-lg {
      padding: 10px !important; }
  .card.price-card h1 {
    position: relative;
    font-weight: 700;
    font-size: 25px; }
    .card.price-card h1 .price {
      font-size: 30px; }
    .card.price-card h1 .dollar-ammount {
      position: relative;
      font-size: 45px; }
    .card.price-card h1 .month {
      font-size: 16px;
      position: relative;
      right: 8px;
      font-weight: 500; }
