.body {
    padding: 16px 32px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button_primary {
    width: 240px;
}

.modal {
    :global(.modal) {
        z-index: 1060;
    }

    :global(.modal-backdrop) {
        z-index: 1059;
    }
}