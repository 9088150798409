.container {
    background: rgb(243, 247, 251);
    z-index: 1055;
    overflow: auto;

    &:before {
        content: '';
        position: absolute;
        height: 370px;
        top: 0;
        left: 0;
        width: 100%;
        background: #fff;
    }
}

.closePanel {
    color: inherit;
    z-index: 1;
}

.closeIcon:global(.btn.btn-link) {
    color: inherit;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
}