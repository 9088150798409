.existingPaymentMethod {
    &__header {
        font-weight: bold;
        margin: 16px 0 4px;
    }
}

.changeCard {
    flex-grow: 0;
    white-space: nowrap;
}

.planCard:global(.card.price-card) {

    &,
    & :global(.card-body) {
        min-height: 0;
    }
}

.useExistingPaymentMethod {
    &:global(.btn.btn-link) {
        color: #757575;
        font-weight: normal;
    }
}

@media (min-width: 992px) {
    .planCol {
        padding-top: 116px;
    }
}