@import "../../../styles/config";

$height: 65px;

.error {
	display: block;
}

.newComment{
	width: 100%;
	padding:16px;
	position:relative;

	&_edit {
		padding-left: 0;
		padding-right: 0;
	}

	textarea{
		resize: none;
	}

	&__textarea {
		&_focused {
			height: $height;
		}
	}

	&__button {
		// display: none;
	}
	&__cancelButton {
		float: right;
	}
	&_focused {
		margin-bottom: 16px;
	}

	&__attachments {
		padding-top: 8px;
		padding-bottom: 15px;
	}
	.attachment{
		color:$light-text-color;
		display: none;
	}
	
	&_expanded {
		margin-bottom:$baseline;
		.commentForm__text {
			min-height: $height;
		}
		// .commentForm__text__control {
			// height: $height;
		// }
		// textarea{
			// height:$height;
		// }
		.newComment__button{
		display: block;
		}
		.attachment{
			display: block;
		}
	}

	&__buttons {
		display: flex;
	}

	&__dropZone {
		flex: 1;
	}

	&__attachButton:global(.btn.btn-flat) {
		float: right;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 24px;

		:global(.material-icons) {
			font-size: inherit;
		}
	}

	&_note {
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.commentForm {
	
&__text {
	&__control {
	//   min-height: 86px;
	}
	&__highlighter {
	  padding: 7px 13px;  // has to match textarea's paddings + borders
	}
  }
}