.content {
    width: 303px;
    padding: 16px 0 10px 16px;
    position: relative;
}

.selectbox {
    &Button:global(.btn.btn-secondary) {
        padding: 6px 12px;

        &:hover {
            background: #f2f6ff;
            border-color: #CAD2E8;
            color: #002180;
        }
    }
}

.dropActive {
    background: rgb(242, 246, 255);
    border-radius: 4px;
}

.uploadSpinner {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    padding: 2px 0 0 4px;

    &Circle {
        width: 20px;
        height: 20px;
    }
}