.avatar {
	width: 34px;
    height: 34px;

	margin-left: -6px;
    border: 2px solid white;
	cursor: pointer;
	
	&:first-of-type {
		margin-left: 0;
	}
}