
.preview:global(.attachment-preview) {
    vertical-align: top;

    &.preview_image {
        padding: 0;

        img {
            border-radius: 8px;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.delete {
    padding: 3px 6px;

    &_progress {
        padding: 3px 2px 1px;
        display: block !important;
    }
}

.attTitle {
    max-height: 32px;
    overflow-y: hidden;
}