@keyframes indefiniteProgress {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.container {
    width: 100%;
    height: 4px;
    border-radius: 1px;
    background: rgb(233, 233, 233);
    position: relative;
    overflow: hidden;
}

.inner {
    position: relative;
    height: 100%;
    width: 100%;
    left: -10%;
    animation: indefiniteProgress 1s infinite;
}

.bar {
    height: 100%;
}