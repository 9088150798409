.comment {
    &_inactive {
        opacity: 0.4;

        &:hover {
            opacity: 0.7;
        }
    }
}

.container {
    position: relative;

    &_uploaded {
        img {
            cursor: crosshair;
        }
    }
}