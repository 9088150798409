.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000002;

    &_hidden {
        opacity: 0;
    }
}

.imgWrapper {
    position: relative;
}

.close {
    position: absolute;
    right: 40px;
    top: 40px;
    color: #000;
    opacity: 0.8;
    font-size: 40px;

    &:hover {
        opacity: 1;
    }
}