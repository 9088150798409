@import "../../styles/config";

.productCardContainer {
	max-width: 950px;
	@include breakpoint(md) {
		max-width: 720px;
	}
}

.productCardWrapper {
	position:relative;
	width:$product-card-width;
	margin:0 auto;
	border:1px solid $border-color;
	display:block;
	@include breakpoint(sm) {
  		width:100%;
  		max-width: $product-card-width;
	}
	&:hover{
		box-shadow: 0 1px 3px 1px rgba(60,64,67,.15);
	}
}

.cardUrl {
	color: $text-color;
}

.cardText {
	color: $extra-light-text-color;
}


.cardActions {
	position:absolute;
	bottom:20px;
	right:20px;
	.dropdown-item{
		&:hover{
			cursor: pointer;
		}
	}
}

:global(.dropdown-item).deleteAction {
	color: $danger-color;
}