.featuresList {
    li {
        position: relative;
        padding-left: 22px;

        &:before {
            content: "check";
            font-family: Material Icons;
            position: absolute;
            top: 3px;
            left: 0;
            line-height: 1;
        }
    }

    hr {
        display: none;
    }
}