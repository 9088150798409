
.input {
    padding: .65rem 1rem;
    border-radius: .3rem;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out;

    &_focus {
        border-color: #0042ff;
    }

    &_invalid {
        border-color: #dc3545;
    }

    &_invalid.input_focus {
        box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
    }
}

.error {
    display: block;
    line-height: normal;
}

.cardLabel {
    white-space: nowrap;
}