.container {
    padding: 18px 26px 52px;
}

.frame {
    background: #fff;
    z-index: 11111;
    border: solid 1px #DFE4F1;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1618);
    width: 281px;
    max-width: 100%;
}

.powered {
    max-height: 0;
    transition: max-height 0.3s ease;
    overflow: hidden;
    margin-bottom: 8px;

    &_visible {
        max-height: 30px;
    }
}