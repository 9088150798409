@import "../../../styles/config";

.board {
	margin-top: -65px;
	height: calc(100vh - 62px);
	display: flex;
	flex-direction: column;
	background: #FAFBFC;
}

.header {
	background: #fff;
	flex-shrink: 0;
	border-bottom: 1px solid #E6EAEE;
	padding: 12px 15px;
	display: flex;
	align-items: center;
}

.columns {
	display: flex;
	flex-grow: 1;
	gap: 19px;
	overflow-x: auto;
	padding: 12px 24px 16px;
}

.column {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 6px;
	width: 320px;
	border: solid 2px transparent;

	&_drop {
		border-color: $primary-color;
	}
}

.columnColor {
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 6px;
	vertical-align: 1px;
	margin-right: 12px;
}

.columnHeader {
	line-height: 42px;
	padding: 0 10px;
	display: flex;
}

.columnHeaderTitle {
	flex-grow: 1;
}

.columnSettings:global(.btn.btn-link) {
	padding: 0;
	color: #7F8FA4;

	&:hover {
		color: #404852;
	}

	i:global(.material-icons) {
		font-size: 16px;
	}
}

.columnStatus {
	position: relative;
	top: 20%;
	text-align: center;
	color: #7F8FA4;
}

.cards {
	flex-grow: 1;
	overflow-y: auto;
	padding: 5px 10px 10px 10px;
}

.card {
	&:hover {
		box-shadow: 0 0 0 1px $primary-color;
	}
}

.noteLimitAlert {
	position: static;
	background: none;

	:global(.btn.btn-sm) {
		display: inline;
		padding: 0;
		margin: 0;
		background: none;
		text-decoration: underline;
	}
}

.overlay {
	bottom: 62px;

	&_top {
		z-index: 1031;
	}
}

.commentsToggle {
	color: #eee !important;
}

.overlayContent {
	display: flex;
	height: 100%;
}

.overlayImg {
	position: relative;
	flex-grow: 1;
}

.overlayClose {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 24px;
	line-height: 1;
	padding: 0;
}

.noteDetailsPanel {
	flex-shrink: 0;
}

.noteView {
	background: none;
	width: auto;

	&Wrapper {
		padding-right: 44px;
		padding-left: 44px;
	}
}

body.dragging * {
	cursor: grabbing;
}