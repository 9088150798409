@import '../../styles/config.scss';

:global(.card.price-card).priceCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;

    &_huge {
        max-width: 380px;
        border-color: rgb(78, 90, 110);
        border-width: 5px;
        box-shadow: -12px 12px 0px 0px rgba(78, 90, 110, 0.1);

        h1 {
            font-size: 36px;
        }

        :global(.card-header) {
            padding-top: 27px;

            h5 {
                margin-top: 3px;
            }
        }

        :global(.card-body) {
            padding-bottom: 29px;

            :global(.btn.btn-primary) {
                font-size: 20px;
                background: rgb(0, 65, 255);
                background: linear-gradient(90deg, rgba(0, 65, 255, 1) 0%, rgba(184, 0, 255, 1) 100%);
                border: none;
            }
        }
    }

    :global(.card-header) {
        border-bottom: none;
        padding-bottom: 0;
    }

    :global(.card-body) {
        display: flex;
        flex-direction: column;

        :global(.card-list) {
            flex-grow: 1;
        }
    }

    .textPrice {
        font-weight: 700;
        font-size: 31px;
        margin-top: 39px;
        margin-bottom: 17px;
    }
}

.shinyText {
    color: $primary-color;
    mask-image: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 100%);
}


@media (min-width: 992px) {
    :global(.card.price-card).priceCard {
        &_huge {
            translate: 0 -15px;
            height: calc(100% + 30px);
        }
    }
}