.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  flex-direction: column;
}

.close {
  padding: 20px 25px;
  color: #fff;
  flex: 0;
  text-align: right;

  &__link:global(.btn.btn-link) {
    color: #fff;
    padding: 0;
    font-size: 42px;
    font-weight: normal;
    line-height: 1;
  }

  &__link:global(.btn.btn-link:hover) {
    text-decoration: none;
  }
}