.form-control.is-invalid, .was-validated .form-control:invalid {
  padding-right: 1rem;
  background: none;
}

/* .form-control.form-control-lg {
  height: calc(1.5em + 1.3rem + 2px);
}*/

.toast-container {
  position: fixed;
}

.route-product #birdseed-widget-container {

  display: none;
}