 @mixin breakpoint($point) {
   @if $point == lg {
     @media (max-width: 1200px) { @content ; }
  }
   @else if $point == md {
     @media (max-width: 992px) { @content ; }
  }
    @else if $point == sm {
     @media (max-width: 768px) { @content ; }
  }
   @else if $point == xs {
     @media (max-width: 576px)  { @content ; }
  }
}

// to use:
//   @include breakpoint(md) {
//     ...
//   }



@include breakpoint(sm) {

}
