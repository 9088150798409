.left {
	overflow: hidden;
	display: flex;
	flex-grow: 100;
}

:global(#bottom-bar) .leftTitle {
	overflow: hidden;
	flex: 1px 1 1;
	position: static;
	padding-top: 10px;
}

.created {
	overflow: hidden;
	text-overflow: ellipsis;
}

.right {
	display: flex;
	justify-content: flex-end;
	white-space: nowrap;
}

:global(.btn.btn-secondary).viewSelector {
	border-color: #B4BBC6;
	font-weight: 600;

	@media (max-width: 768px) {
		&:after {
			display: none;
		}
	}
}

.viewSelectorMenu :global(.dropdown-item)> :global(.d-inline-block) {
	display: none !important;
}