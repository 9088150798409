@import "../../styles/config";

.container {
	border:1px solid $border-color;
    margin: auto;
    margin-bottom:$baseline;
	height: 311px;
	background-image:
		linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
		linear-gradient( tint($border-color,30%) 190px, transparent 0 ),
		linear-gradient( tint($border-color,30%) 20px, transparent 0 ),
		linear-gradient( tint($border-color,30%) 20px, transparent 0 );
	
	background-repeat: no-repeat;

	background-size:
		50px 400px, /* highlight */
		calc(100% - 40px) 200px,
		calc(100% - 40px - 108px) 200px,
		calc(100% - 40px - 158px) 200px;

	background-position:
		0 0, /* highlight */
		20px 20px,
		20px 230px,
		20px 260px;

	animation: shine 1s infinite;

	@include breakpoint(sm) {
		width:100%;
	}
}


@keyframes shine {
	to {
		background-position:
			100% 0, /* move highlight to right */
			20px 20px,
			20px 230px,
			20px 260px;
	}
}