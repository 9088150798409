@import "../styles/config";

.commentNumber {
    cursor: pointer;
    background: $tertiary-color;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    box-sizing: border-box;
    color: white;
    line-height: 39px;
    font-weight: 500;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .24);
    transition: all 0.2s ease, left 0s, top 0s;

    &_sm {
        width: 27px;
        height: 27px;
        line-height: 27px;
        font-size: 14px;
    }

    &_positioned {
        position: absolute;
        z-index: 1;
        transform: translate(-15px, -15px);
    }

    &_readonly {
        cursor: default;
        border: solid 3px #fff;
    }

    &_interactive {
        line-height: 30px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 50px;
            height: 50px;
            transform: translate(-10px, -10px);
            border-radius: 100px;
            background-color: rgba(38, 193, 100, 0.3);
            transition: all 0.2s ease;
        }

        &:hover,
        &.commentNumber_drag {
            line-height: 34px;
            width: 34px;
            height: 34px;
            transform: translate(-17px, -17px);
        }

        &:hover {
            &:before {
                transform: translate(-8px, -8px) scale(1.12);
                /* scale up to 56px */
            }
        }

        &.commentNumber_drag {
            &:before {
                transform: translate(-8px, -8px) scale(1.36);
                /* scale up to 68px */
            }
        }
    }
}