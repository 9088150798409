.container {
    position: fixed;
    top: -18px;
    left: 20px;
    z-index: 1000000003;
    color: #fff;
    font-weight: normal;
    font-size: 15px;
    background: rgba(0,0,0,0.6);
    line-height: 20px;
    padding: 8px 9px;
    border-radius: 5px;
    pointer-events: none;
}
