@import "../../../styles/config";

.commentsContainer {
	height: 100%;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	width: 320px;
	flex-shrink: 0;
	background: white;
	border-left: 1px solid $border-color;

	&__resolvedHeader {
		border-bottom: solid 1px $border-color;

		@include breakpoint(md) {
			display: none;
		}

		:global(.btn) {
			white-space: nowrap;
		}
	}

	&:not([class*="Review_noteDetails_left__"]) {
		@include breakpoint(md) {
			padding-top: 55px;
		}

		@include breakpoint(sm) {
			position: fixed;
			padding-top: 0;
			width: 100%;
			right: 0px;
			bottom: 0;
			height: auto;
			border-radius: 10px;
			z-index: 1031;
			top: 278px;
			box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.2);
			display: none;

			&.commentsBarOpen {
				display: block;
			}
		}
	}

	.commentsWrapper {
		overflow-y: auto;

		@include breakpoint(sm) {
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		.new-comment {
			width: 100%;
			padding: 16px;
			position: relative;

			textarea {
				resize: none;
			}

			.add-comment-btn {
				display: none;
			}

			.attachment {
				color: $light-text-color;
				display: none;
			}

			&.focused {
				margin-bottom: $baseline;

				textarea {
					height: 65px;
				}

				.add-comment-btn {
					display: block;
				}

				.attachment {
					display: block;
				}
			}
		}

		.commentsList {
			@include breakpoint(sm) {
				overflow-y: auto;
				margin-bottom: 61px;
			}
		}
	}
}

.newComment {
	@include breakpoint(sm) {
		position: fixed;
		background: white;
		z-index: 1032;
		bottom: 0;
		border-top: 1px solid #E6EAEE;
		padding: 7px;
		padding-top: 10px;
	}
}