// color vars

$primary-color: #0042FF;
$secondary-color: #0B2A5C;
$tertiary-color: #26C164;

// greys

//Tbl row striped
$off-white-color: #FAFBFC;

//Tbl row selected, Dropdown hover
$off-white-dark-color: #F5F8FA;

//Borders, Tbl header, light icons
$off-white-darker-color: #E6EAEE;

//Buttin borders, checkbox borders,
$lightest-gray-color: #B4BBC6;

//Placeholder text
$lighter-gray-color: #C5D0DE;

//Extra light text
$light-gray-color: #B4BBC6;

//Light text, labels
$gray-color: #7F8FA4;

//Text color
$dark-gray-color: #354052;

//Dark text color
$darker-gray-color: #393C3E;


//links

$link-color: #0042FF;
$link-hover-color:shade($secondary-color, 50%);


// alerts

$success-color: #26C164;
$danger-color: #FF4E4A;
$warning-color: #FFAA00;

// text

$placeholder-color: $lighter-gray-color;
$extra-light-text-color: $light-gray-color;
$light-text-color: $gray-color;
$text-color: $dark-gray-color;
$dark-text-color: $darker-gray-color;


$background-color: #fff;
$border-color: $off-white-darker-color;

// units

$baseline: 16px;
$base-border-radius:3px;
$base-font-size:16px;
$navbar-height:56px;


// font vars

$regular-font-name: 'Source Sans Pro', sans-serif;
$social-font-name:"socialicoregular", "Helvetica", serif;
$social-font-name-two:"socialico_plusregular", "Helvetica", serif;


//transitions

$base-transition-duration:.13s;


//shadows

$box-shadow-elv-one:0 2px 4px 0 rgba(0,0,0,0.12);
$box-shadow-elv-two:0 2px 10px 0 rgba(0,0,0,0.10), 0 2px 4px 0 rgba(0,0,0,0.04);

$control-nav-height: 61px;