.option {
    &:hover .onlyBtn {
        display: block;
    }

    &:global(.dropdown-item) .statusIcon {
        i {
            left: 0;
            top: -1px;
            margin-right: 8px;
        }

        img {
            position: relative;
            top: -1px;
            left: -3px;
            margin-right: 2px;
        }
    }
}

.onlyBtn {
    padding: 0;
    float: right;
    margin-right: 24px;
    display: none;
}