@import "../../../styles/config";

.reviewContainer {
	bottom: $control-nav-height;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	display: flex;
}


//this is both the menu button and the close button
.showNotesToggle {
	display: none;

	@include breakpoint(md) {
		display: inline-block;
	}
}

.notesOrderSelector:global(.dropdown-toggle) {
	&:after {
		position: static;
	}
}

.notesListContainer {
	height: 100%;
	position: relative;
	top: 0;
	left: 0;
	width: 300px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex-shrink: 0;

	&_uploaded {
		width: 320px;
	}

	@include breakpoint(md) {
		position: absolute;
		height: 100%;
		width: 300px;
		left: 0px;
		top: 0;
		bottom: 0;
		z-index: 5;
		display: none;

		&.notesBarOpen {
			display: flex;
		}
	}

	.notesListHeader {
		padding: 16px;
		background: white;
		border-right: 1px solid $border-color;

		:global(.filter-button) {
			padding: 5px 10px;
		}
	}

	.notesListBody {
		position: relative;
		overflow-y: auto;
		height: 100%;
		width: 100%;
		border-right: 1px solid $border-color;
		background: white;

		&_withAlert {
			padding-bottom: 132px;
		}
	}

	.notesListFooter {
		background: white;
		border-right: 1px solid $border-color;

		.showResolved {
			padding: 15px 12px;

			:global(.custom-control) {
				padding-left: 4px;
			}

			button {
				color: $light-text-color !important;
				font-size: 13px;

				i {
					font-size: 21px;
					top: 6px;
					margin-right: 4px;
				}
			}
		}
	}
}

.imgContainer_uploaded .showCommentToggle {
	display: none;
}

.noteContainer {
	width: calc(100% - 280px);
	height: 100%;
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	overflow: hidden;

	///the whole image and convo wrapper
	@include breakpoint(md) {
		width: 100%;
	}

	.noteBody {
		overflow-y: auto;
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;

		@include breakpoint(md) {
			padding-top: 55px;
		}

		.noteInfo {
			////image display area
			display: flex;
			height: 100%;
			width: 100%;

			@include breakpoint(md) {
				overflow: hidden;
			}
		}
	}
}

// padding-top:65px;
.imgContainer {
	height: 100%;
	overflow-y: scroll;
	display: flex;
	width: calc(100% - 320px);
	background: $off-white-color;
	position: relative;
	align-items: center;
	justify-content: center;

	&_uploaded {
		width: auto;
		flex-grow: 1;
	}

	@include breakpoint(sm) {
		width: 100%;
	}

	.imgWrapper {
		max-height: 100%;
		position: relative;

		&_video {
			flex-grow: 1;
		}

		.imgCommentContainer {
			&_video {
				width: 100%;
				max-height: 100%;
			}

			position:relative;
			margin-left: auto;
			margin-right: auto;
			padding:10px;

			&>div>img {
				display: block;
				max-width: 100%;
				height: auto;
				border: 1px solid $border-color;
				box-sizing: content-box;

				&:global(.drawOverlay) {
					position: absolute;
					top: 0;
					left: 0;
					border: solid 1px transparent;
				}
			}
		}
	}
}


.notesZero {
	display: flex;
	height: 100%;

	&__container {
		padding: $baseline*2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.notesLoading {
	text-align: center;
}

.loading {
	&__spinnerArea {
		padding-bottom: 8px;
	}

	&__spinner {
		width: 59px;
		height: 59px;
		border-width: 3px;
	}
}

.imageOverlay {
	z-index: 10000;

	&__body {
		text-align: center;
		flex-grow: 1;
		position: relative;
	}

	&__img {
		position: absolute;
		max-width: 80%;
		max-height: 100%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__footer {
		text-align: center;
		font-size: 20px;
		padding: 25px 0 65px;

		&__link,
		&__link:hover,
		&__link:active {
			color: #fff;
		}

		&__linkIcon {
			vertical-align: top;
			padding-right: 8px;
		}
	}
}

.pageSeparator {
	display: flex;
	align-items: center;
	padding: 0 16px;
	font-weight: 700;

	&:not(:first-child) {
		padding-top: 8px;
	}

	&Content {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&Line {
		flex-grow: 1;

		&:after {
			content: "";
			display: block;
			border-top: solid 1px #E6EAEE;
			opacity: 0.5;
			margin-left: 10px;
		}
	}
}

.leftNoteDetails {
	position: absolute;
	background: #fff;
	border-right: 1px solid #E6EAEE;
	width: 320px;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	box-sizing: content-box;
	display: flex;
	flex-direction: column;

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-shrink: 0;
		border-bottom: solid 1px #E6EAEE;
	}

	@include breakpoint(md) {
		padding-top: 55px;

		&__header {
			display: none;
		}
	}
}


.noteDetails_left.noteDetails_left {
	height: auto;
	flex-grow: 1;
}