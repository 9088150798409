@import "../../../styles/config";

.noteThumbnail {
	padding: $baseline;
	cursor: pointer;
	background: white;
	position: relative;
	transition-duration: .2s;

	&_pending {
		padding-bottom: 0;
	}

	&__body {
		overflow-x: hidden;
		overflow-wrap: break-word;
	}

	&__number {
		flex-shrink: 0;
	}

	&:hover {
		background: $off-white-dark-color;
	}

	img {
		border: 3px solid $border-color;
		border-radius: 4px;
	}

	h5 {
		max-height: 30px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&_active {
		background: $link-color;

		h5,
		h6 {
			color: white !important;
		}

		&:hover {
			background: $link-color;
		}

		:global(.note-status-preview i) {
			color: white !important;
		}
	}

	:global(.note-status-preview i) {
		font-size: 18px;
		color: #7F8FA4;
	}

	&_resolved {
		:global(.note-status-preview i) {
			color: #26C164;
		}
	}
}

.mention {
	&_active {
		background: #5883ff;
	}
}

.statusPreview {
	i {
		vertical-align: middle;
	}

	img {
		border: none;
	}
}

.assigneePreview img,
.priorityPreview img {
	border: 0 !important;
}

.boardCard {
	cursor: pointer;
	line-height: 18px;
	padding: 12px;
	border-radius: 6px;
	background: #fff;
	box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
	transition: opacity 0.2s ease;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&_dragged {
		opacity: 0.2;
	}
}

.boardCardFooter {
	display: flex;
	justify-content: space-between;
}

.imagePreview {
	max-width: 300px;
	max-height: 200px;
}

.previewTrigger {
	font-size: 22px;
	line-height: 22px;
	vertical-align: middle;
	margin-left: 5px;
}

.previewMore {
	margin-left: 5px;
	font-size: 13px;
}

.imagePreviewTooltip {
	pointer-events: none;

	:global(.tooltip.show) {
		opacity: 1;
	}

	:global(.tooltip-inner) {
		max-width: none;
		padding: 0.5rem;
		background: rgba(0, 0, 0, 0.9);
	}
}