@import "../../../styles/config";

.commentAttachment {
	&__preview {
		border: solid 1px $border-color;
	}
}

.comment{
	padding:16px;
	width:100%;
	position:relative;
	transition: background-color .5s;
	padding-bottom: 10px;
	&__body {
		overflow-x: hidden;
		overflow-wrap: break-word;
	}
	&__img {
		border-radius:4px;
		margin-bottom:5px;
	}
	&_firstReply {
		border-top: solid 1px #e6eaee;
		padding-top: 32px;
		
		.actions {
			top: 22px;
		}
	}
	&_highlight {
		background-color: rgba(0, 66, 255, 0.1);
	}
}

.text {
	white-space: pre-wrap;

	&_pending {
		width: 57%;
	}
}

.actions {
    position: absolute;
    right: 10px;
    top: 6px;

	&__toggle:global(.btn.btn-link) {
		color: $light-text-color;
	}
}

