:global(.btn.btn-secondary).resolved {

    &,
    &:active {
        background: #26c164 !important;
        border: #26c164 !important;
        color: #fff !important;
        height: 33px;
    }
}

.statusIcon {
    margin-right: 8px;
    position: relative;
    top: -1px;
}

:global(.dropdown-menu .dropdown-item) .menuStatusIcon {
    margin-right: 8px;

    &>i {
        left: 0;
        top: -1px;
    }

    &_final {
        position: relative;
        top: -1px;
        left: -3px;
        margin-right: 2px;
    }
}