.avatarSuccess {
	background: none;
}

.avatarLoading {
	display: inline-block;
	background: rgb(229, 234, 243);
}

.userNameLoading {
	padding-top: 4px;
}