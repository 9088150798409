
.container {
  color: #212529;
}

.button:global(.btn.btn-secondary) {
  padding-top: 8px;
  background: none;
  border-color: #dee0e3;
  width: 100%;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: normal;
  text-align: left;
  padding-right: 20px;

  &.button_invalid {
    color: #dc3545;
    border-color: #dc3545;
  }
}

.button:global(.dropdown-toggle)::after {
  position: absolute;
  right: 10px;
  top: 50%;
}

.header {
  opacity: 0.8;
  &:not(:first-child) {
    padding-top: 15px;
  }
}