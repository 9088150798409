.text {
  &__control {
    min-height: 86px;
    border: none;
    padding: 0;
  }
  &__highlighter {
    padding: 7px 6px;  // has to match textarea's paddings + borders
  }
  &__input {
    padding: 7px 6px;  // has to match textarea's paddings + borders
    border: none;
  }
  &__mention {
    background: #e8f5fa;
  }
}
