@import "../../styles/config";

:global(body.modal-open) {
	padding-right: 0 !important;
}

.modal {
	width: 435px;
}

.title {
	font-weight: 600;
}

.dropZone {
	border: dashed 2px #ccc;
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
}

.icons {
	padding: 38px 0 14px;

	i {
		font-size: 48px;
	}

	i:first-child {
		transform: rotate(-20deg);
	}

	i:last-child {
		position: relative;
		left: -10px;
		background-color: #fff;
		transform: rotate(20deg);
	}
}

.previews {
	cursor: default;
	text-align: left;
	max-height: 300px;
	overflow: auto;
	margin-top: 10px;

	&>div {
		margin-top: 4px;
	}
}

.browse {
	margin: 0;
	font-size: 14px;

	:global(.btn.btn-link) {
		padding: 0;
		color: inherit;
		font-size: inherit;
		text-decoration: underline;
		font-weight: normal;
		vertical-align: baseline;
	}
}

.upload:global(.btn.btn-lg) {
	min-width: 150px;
	font-size: 19px;
	padding: 13px 32px;
}