
.authorName {
    padding-bottom: 10px;
    position: relative;

    img {
        position: absolute;
        top: 7px;
        left: 7px;
        z-index: 5;
        width: 20px;
        height: 20px;
    }
}
.authorNameInput {
    font-size: 13px;
    height: 34px;
    border-radius: 4px;
    background: #fff;
    padding-left: 34px;

    &, &:focus {
        border: solid 1px #dee0e3;
    }
}