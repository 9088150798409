.logo {
    margin: 40px 0 30px;
    height: 60px;
}

.close {
    text-transform: uppercase;
    padding-left: 3rem;
    padding-right: 3rem;
    font-weight: 500;
}