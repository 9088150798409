.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    :global(.vjs-theme-fantasy) {
        --vjs-theme-fantasy--primary: #0042FF;
    }
}

:global(.video-js.vjs-theme-fantasy) {
    :global(.vjs-big-play-button).playButton {
        background: rgba(0,0,0,0.07);
        border-radius: 100px;
        width: auto;
        height: auto;
        margin-top: -53px;
        margin-left: -53px;
        padding: 8px;
        transition: transform 0.2s ease;
        z-index: 1;

        &:hover {
            transform: scale(1.1);
        }

        :global(.vjs-icon-placeholder) {
            background: rgba(255,255,255,0.81);
            display: inline-block;
            width: 90px;
            height: 90px;
            text-align: center;
            border-radius: 100px;

            &::before {
                display: none;
            }

            img {
                vertical-align: top;
                margin-top: 26px;
                margin-left: 7px;
            }
        }
    }

    .playButtonBackdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-color: rgba(0,0,0,0.1);
        transition: background-color 0.2s ease;
    
        &_hover {
            background-color: rgba(0,0,0,0.2);
        }
    }

    &:global(.vjs-controls-disabled), &:global(.vjs-has-started), &:global(.vjs-using-native-controls), &:global(.vjs-error) {
        .playButtonBackdrop {
            display: none;
        }
    }
}
