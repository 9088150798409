.container {
  display: flex;
  border-radius: 10px;
  padding: 7px 6px 7px 9px;
  display: flex;
  background: #F4F6F8;
}

.img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 3px;
}

.name {
  flex-grow: 1;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.remove {
  font-size: 16px;
  line-height: 1;
  padding: 0 3px;

  &__icon {
    vertical-align: middle;
    font-size: 16px;
    color: #7f8fa4;
  }
}
