@import "../../styles/config";

.productCardContainer {
	max-width: 950px;

	@include breakpoint(md) {
		max-width: 720px;
	}
}

.input:global(.form-control.form-control-lg) {
	height: 59px;
}

.buttons {
	flex-grow: 0;
	white-space: nowrap;

	button:first-child {
		margin-right: 10px;
	}

	button:last-child {
		margin-left: 10px;
		box-shadow: none;

		i {
			margin-left: 8px;
			font-size: 28px;
			transform: rotate(20deg);

			@include breakpoint(sm) {
				display: none;
			}
		}
	}
}