.container {
    background: #fff;
    z-index: 1030;
    overflow: auto;
    padding-top: 64px;
}

.closePanel {
    background-color: #fff;
    color: inherit;
    padding-bottom: 0;
    position: fixed;
    width: 100%;
    top: 0;
    padding: 9px 60px;
    z-index: 1;
}

.closeIcon:global(.btn.btn-link) {
    color: inherit;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
}
