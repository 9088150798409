.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  overflow: hidden;

  &_xxs {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
    font-weight: bold;
  }

  &_xs {
    width: 30px;
    height: 30px;
    font-size: 13px;
    line-height: 30px;
  }

  &_xl {
    width: 80px;
    height: 80px;
    font-size: 34px;
    line-height: 80px;
  }

  &_initial {
    background-color: #FF8E4A;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;

  &_round {
    position: relative;
    top: -3px;
    left: -3px;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
  }
}
